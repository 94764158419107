import React, { useCallback, useMemo } from 'react';
import usePrinter from '@hooks/printers/usePrinter';
import {
  getUnsavedPrinterModifications,
  getMachineModifications,
} from '@selectors/printerSelectors.js';
import { useDispatch, useSelector } from 'react-redux';
import { defaultSixAxisRobotSettings } from '@constants/defaultSixAxisRobotSettings.js';
import { defaultGantrySettings } from '@constants/defaultGantrySettings.js';
import {
  changeMachineModel,
  changeMachineSetting,
} from '@actions/printerActions.js';
import { Category } from './Category.js';
import { useIntl } from 'react-intl';
import { CategoryDisplayNames } from '@constants/printerCategories.js';
import { machineConstants } from '@constants/printers/machineConstants';
import { getHardwareSelectionSettings } from '@constants/printers/hardwareSelectionSettings';
import { getCurrentUser } from '@selectors/loginSelectors.js';
import {
  checkCustomOption,
  getDefaultMachineValues,
  getMachineSelectionSetting,
  getSetting,
} from '@components/Printers/SettingsUtils';
import {
  flipToolCalibration,
  updateDefaultToolCalibration,
} from '../../../lib/extruders/ExtruderUtils.js';
import { getMachineBrand } from '../../../lib/machines/MachineUtils.js';
import { robotBrandDefinitions } from '../../../constants/printers/robotBrandDefinitions.js';

/**
 * This is a separate category used for selecting printer components. It has been separated into
 * its own class so that the settings can be re-rendered on component change to match the selected
 * component.
 * @param {*} param0
 * @returns
 */
export const HardwareSelection = ({ onChange, actionHandler, printer }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentUser = useSelector(getCurrentUser());
  const machineSettings = useSelector(getMachineModifications(printer.id));
  const printerModifications = useSelector(
    getUnsavedPrinterModifications(printer?.id),
  );

  const { getAllPrinterDefinitions } = usePrinter();
  const {
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  } = getAllPrinterDefinitions();

  const handleChange = useCallback(
    (changedSetting, valueString) => {
      dispatch(changeMachineSetting(printer.id, changedSetting, valueString));
      onChange(changedSetting, valueString);
    },
    [onChange, dispatch, printer],
  );

  const machineSelector = useMemo(
    () =>
      getHardwareSelectionCategory({
        onChange,
        actionHandler,
        intl,
        dispatch,
        printerModifications,
        machineSettings,
        machineDefinitions,
        extruderDefinitions,
        printingBedDefinitions,
        plinthDefinitions,
        enclosureDefinitions,
        currentUser,
        printer,
      }),
    [
      onChange,
      actionHandler,
      intl,
      dispatch,
      printerModifications,
      machineSettings,
      printer,
      machineDefinitions,
      extruderDefinitions,
      printingBedDefinitions,
      plinthDefinitions,
      enclosureDefinitions,
      currentUser,
    ],
  );

  const defaultMachineSettings = useMemo(() => {
    if (!machineSettings) return [];
    const isRobotMachine =
      machineSettings.type === machineConstants.sixAxisRobotDiscriminator;
    if (isRobotMachine) return defaultSixAxisRobotSettings;
    return defaultGantrySettings;
  }, [machineSettings]);

  const settings = useMemo(
    () =>
      Object.entries(machineSettings).reduce((acc, [settingName, value]) => {
        const machineSetting = getSetting(
          defaultMachineSettings,
          settingName,
          value,
        );
        return machineSetting ? [...acc, machineSetting] : acc;
      }, []),
    [machineSettings, defaultMachineSettings],
  );

  const settingsPerCategory = useMemo(
    () =>
      settings.reduce(
        (acc, setting) => ({
          ...acc,
          [setting.category]: [...(acc[setting.category] || []), setting],
        }),
        {},
      ),
    [settings],
  );

  const categories = useMemo(
    () =>
      [machineSelector].concat(
        Object.entries(settingsPerCategory).map(([categoryTitle, settings]) => {
          const categorySettingsUserCanSee = settings.filter((setting) => {
            return (
              !setting.authorizedRoles ||
              setting.authorizedRoles.includes(currentUser?.role)
            );
          });
          return (
            categorySettingsUserCanSee.length > 0 && (
              <Category
                key={categoryTitle}
                onChange={handleChange}
                settingsInCategory={settings}
                selectedPrinter={printer}
                categoryTitle={`Machine - ${CategoryDisplayNames[categoryTitle]}`}
              />
            )
          );
        }),
      ),
    [printer, handleChange, machineSelector, settingsPerCategory, currentUser],
  );

  if (!machineSettings) return machineSelector;

  return categories;
};

/**
 * Flips the tool calibration reference frame if the brand of the selected machine
 * has changed.
 */
function flipToolCalibrationIfNeeded(
  dispatch,
  machineDefinitions,
  printerModifications,
  printer,
  valueString,
  type,
) {
  if (type === machineConstants.gantryDiscriminator) return;

  const newMachineBrand = getMachineBrand(
    machineDefinitions,
    valueString,
    printer,
  );
  const isMachineAlreadyModified = printerModifications?.settings?.robotType;

  const currentBrand = isMachineAlreadyModified
    ? getMachineBrand(
        machineDefinitions,
        printerModifications.settings.robotType,
      )
    : printer.machine.brand;

  const { isFlangeInverted: currentIsFlangeInverted } =
    robotBrandDefinitions[currentBrand];
  const { isFlangeInverted: newIsFlangeInverted } =
    robotBrandDefinitions[newMachineBrand];

  if (currentIsFlangeInverted !== newIsFlangeInverted) {
    const {
      toolX = printer.toolX,
      toolY = printer.toolY,
      toolZ = printer.toolZ,
      toolA = printer.toolA,
      toolB = printer.toolB,
      toolC = printer.toolC,
    } = printerModifications?.settings || {};

    const toolCalibration = flipToolCalibration({
      toolX,
      toolY,
      toolZ,
      toolA,
      toolB,
      toolC,
    });

    updateDefaultToolCalibration(dispatch, printer, { ...toolCalibration });
  }
}

/**
 * Returns the category containing the settings to change each printer component.
 * @param {*} intl
 * @param {*} dispatch
 * @param {*} printerId
 * @param {*} machineSettings
 * @returns
 */
function getHardwareSelectionCategory(params) {
  const {
    onChange,
    actionHandler,
    intl,
    dispatch,
    printerModifications,
    machineSettings,
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
    currentUser,
    printer,
  } = params;
  const printerId = printer.id;
  const machineBrand = machineSettings.brand || printer.machine.brand;

  const handleModelChange = (changedSetting, valueString) => {
    if (checkCustomOption(valueString, changedSetting, dispatch, printer)) {
      return;
    }
    const machineDefaults = getDefaultMachineValues(
      machineSettings?.id,
      machineDefinitions,
      printer,
      valueString,
    );

    dispatch(changeMachineModel(printerId, machineDefaults));

    flipToolCalibrationIfNeeded(
      dispatch,
      machineDefinitions,
      printerModifications,
      printer,
      valueString,
      machineDefaults.type,
    );
    onChange(changedSetting, valueString);
  };
  const machineSetting = {
    ...getMachineSelectionSetting(
      machineDefinitions,
      machineSettings.modelName,
      currentUser,
    ),
    onChangeOverride: handleModelChange,
  };

  const hardwareSettings = getHardwareSelectionSettings(
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
    onChange,
    dispatch,
    printer,
    machineBrand,
    currentUser,
  );

  const categorySettingsUserCanSee = hardwareSettings.filter((setting) => {
    return setting.authorizedRoles.includes(currentUser?.role);
  });

  return (
    categorySettingsUserCanSee.length > 0 && (
      <Category
        onChange={onChange}
        actionHandler={actionHandler}
        key="printerComponents"
        settingsInCategory={[machineSetting].concat(hardwareSettings)}
        categoryTitle={intl.formatMessage({
          id: 'printers.machine.category.selection',
          defaultMessage: 'Printer Components',
        })}
        selectedPrinter={printer}
      />
    )
  );
}
