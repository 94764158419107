// Dialog IDS
export const ModalDataTypes = {
  CREATE_TEMPLATE: 'createTemplate',
  CREATE_ANNOTATION: 'createAnnotation',
  DELETE_CUSTOM_TEMPLATE_WARNING: 'deleteCustomTemplateWarning',
  DELETE_TEMPLATE_WARNING: 'deleteTemplateWarning',
  EXPLODE_TEMPLATE: 'explodeTemplate',
  RECOMPUTE_WORKFLOW: 'recomputeWorkflowPrompt',
  UPDATE_PRINTER_WORKFLOW: 'updatePrinterWorkflow',
  UPDATE_NOZZLE_WORKFLOW: 'updateNozzleWorkflow',
  FORGOT_PASSWORD: 'forgotPassword',
  UPDATE_CONCEPT_OPERATORS_USING_NOZZLE: 'updateConceptOperatorsUsingNozzle',
  UPDATE_CONCEPT_OPERATORS_USING_MATERIAL:
    'updateConceptOperatorsUsingMaterial',
  UPDATE_MATERIAL: 'updateMaterial',

  PROMPT: 'prompt', // This is a generic prompt dialog
  CREATE_FLOW: 'createFlowDialog',
  SEND_COPY: 'sendCopy',
  EDIT_WORKFLOW: 'editWorkflow',
  EDIT_PROJECT: 'editProject',
  EDIT_FILE: 'editFile',
  CREATE_PRINTER: 'createPrinter',
  DELETE_PRINTER: 'deletePrinter',
  DELETE_NOZZLE: 'deleteNozzle',
  NAVIGATION: 'navigation',
  OPERATORS_LIBRARY: 'operatorsLibrary',
  MANAGE_VISIBILITY: 'manageVisibility',
  WORKFLOW_DETAILS: 'workflowDetails',
  EDIT_OPERATOR: 'editOperator',
  RESET_PASSWORD: 'resetPassword',
  CONNECT_MFA: 'connectMFA',
  CREATE_USER: 'createUser',
  EDIT_USER: 'editUser',
  USER_INFO: 'userInfo',
  CREATE_MATERIAL: 'createMaterial',
  CLONE_MATERIAL: 'cloneMaterial',
  PUBLISH_FILE: 'publishFile',
  TALK_TO_AI: 'talkToAi',
  YOUTUBE_VIDEO: 'YOUTUBE_VIDEO',
};

export const MODAL_IDS = {
  PRINTER_SENSOR_DATA: 'printerSensorData',
  WELCOME: 'welcome',
  FILE_BROWSER: 'fileBrowser',
  FILE_PREVIEW: 'filePreview',
  VISUAL_TOOL: 'visualTool',
};
