export const LineTypes = {
  OUTER_WALL: 'OUTER_WALL',
  INNER_WALL: 'INNER_WALL',
  INFILL: 'INFILL',
  SUPPORT_INTERFACE: 'SUPPORT_INTERFACE',
  SUPPORT: 'SUPPORT',
  SKIN: 'SKIN',
  LATTICE: 'LATTICE',
  BRIM: 'BRIM',
  MILLING: 'MILLING',
};
