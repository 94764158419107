export const FileTypes = {
  obj: 'obj',
  stl: 'stl',
  step: 'step',
  stp: 'stp',
  aib: 'aib',
  gcode: 'gcode',
  '3mf': '3mf',
  dxf: 'dxf',
  gltf: 'gltf',
};
