import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Main from '@containers/Main';
import Providers from '@containers/Providers';

const appContainer = document.getElementById('ai-sync');

// Create a root.
const root = createRoot(appContainer);

const renderApp = () => {
  root.render(
    <BrowserRouter>
      <Providers>
        <Main />
      </Providers>
    </BrowserRouter>,
  );
};

renderApp();

if (module && module.hot) {
  module.hot.accept('@containers/Main', () => renderApp());
}
