import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import React from 'react';
import PostProcessorInput from '@components/Printers/PostProcessorConfig/PostProcessorInput';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { TOOLTIP_POSITION_BOTTOM_LEFT } from '@components/2-molecules/Tooltip';

const PostProcessorParameters = ({ isSubmitting }) => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const intl = useIntl();

  return (
    <SettingsCategory
      title={intl.formatMessage({
        id: 'printers.postprocessor.isProcessParametersEnabled.label',
        defaultMessage: 'Process parameters',
      })}
      dataTestId={`printer-custom-post-processor__setting-isProcessParametersEnabled`}
      withDividerBottom
      endingCheckboxChecked={Boolean(values['isProcessParametersEnabled'])}
      endingCheckboxDirty={checkIfFieldIsDirty(
        values,
        initialValues,
        'isProcessParametersEnabled',
      )}
      onEndingCheckboxClick={() => {
        setFieldValue(
          'isProcessParametersEnabled',
          !values['isProcessParametersEnabled'],
        );
      }}
      expand={Boolean(values['isProcessParametersEnabled'])}
    >
      <>
        <PostProcessorInput
          label="printers.postprocessor.processParametersIDs.label"
          descriptionLabel="printers.postprocessor.processParametersIDs.description.label"
          optionName="processParametersIDsAction"
          textName="processParametersIDsInstructions"
          isSubmitting={isSubmitting}
        />
        <FormikField
          component={SettingTextField}
          label={intl.formatMessage({
            id: 'printers.postprocessor.defaultProcessParametersID.label',
          })}
          labelTooltip={{
            tooltipAppearDelay: 200,
            tooltipWidth: 350,
            tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
            tooltipOffsetRight: 44,
            tooltipBridge: false,
            tooltipInfoRow: {
              label: intl.formatMessage({
                id: 'printers.postprocessor.defaultProcessParametersID.tooltip.label',
              }),
            },
          }}
          dataTestId={`printer-custom-post-processor__setting-defaultProcessParametersID`}
          name={'defaultProcessParametersID'}
          field1={{
            disabled: isSubmitting,
            type: 'number',
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              'defaultProcessParametersID',
            ),
          }}
        />
      </>
    </SettingsCategory>
  );
};

PostProcessorParameters.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorParameters;
