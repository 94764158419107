import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import { DROP_DOWN_MENU_ID } from '@components/2-molecules/DropDownMenu';
import DropDownField from '@components/1-atoms/DropDownField';
import SettingActionRow from '@components/2-molecules/SettingActionRow';
import { ROUTES } from '@constants/router';
import getIntlProvider from '@utils/getIntlProvider';
import { attachBlurEventToTheRelatedTarget } from '@utils/dom';
import { Fields } from './SelectProject.styled';

const intl = getIntlProvider();

const SelectProject = ({
  dataTestId = 'select-project',
  title = intl.formatMessage({
    id: 'createproject.workflow.select.project.header.title',
    defaultMessage: 'Select Project',
  }),
  subtitle = intl.formatMessage({
    id: 'createproject.workflow.select.project.header.subtitle',
    defaultMessage: 'Select the destination project for your workflow',
  }),
  handleCreateNewProject,
}) => {
  const intl = useIntl();
  const { projectsQuery } = useProjectQueries();
  const { refetch: projectsQueryRefetch } = projectsQuery;
  const form = useFormContext();
  const { isSubmitted } = form.formState;

  const getProjectsDropDownMenuItems = useCallback(() => {
    return projectsQuery.data
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((project) => ({
        label: project.name,
        value: project,
      }));
  }, [projectsQuery]);

  useEffect(() => {
    projectsQueryRefetch();
  }, [projectsQueryRefetch]);

  return (
    <>
      <PageHeader
        variant={PAGE_HEADER_VARIANT_MEDIUM}
        title={title}
        subtitle={subtitle}
      />

      <Fields>
        <Controller
          name="project"
          rules={{
            required: intl.formatMessage({
              id: 'createproject.project.validation.required',
              defaultMessage: 'Please select an option',
            }),
          }}
          render={({ field, fieldState }) => {
            const error =
              (fieldState.isTouched || isSubmitted) && fieldState.error;
            const options = getProjectsDropDownMenuItems();

            const selectedOption = options?.find(
              (option) => option?.value?.id === field?.value?.id,
            ) || { label: '', value: '' };
            const dropDownMenuItems = options?.map((option) => ({
              ...option,
              selected: option.value?.id === selectedOption.value?.id,
              onClick: () =>
                field.onChange({ target: { value: option?.value } }),
            }));

            return (
              <DropDownField
                dataTestId={`${dataTestId}-project`}
                label={intl.formatMessage({
                  id: 'createproject.workflow.projects.label',
                  defaultMessage: 'Projects',
                })}
                placeholder={intl.formatMessage({
                  id: 'createproject.workflow.projects.placeholder',
                  defaultMessage: 'Select Project',
                })}
                error={!!error}
                supportingText={error?.message}
                value={selectedOption.label}
                onBlur={attachBlurEventToTheRelatedTarget(
                  DROP_DOWN_MENU_ID,
                  field.onBlur,
                )}
                dropDownMenuItems={dropDownMenuItems}
                emptyDropDownStateIconName="precision_manufacturing_0"
                emptyDropDownStateTitle={intl.formatMessage({
                  id: 'createproject.workflow.projects.empty_state.title',
                  defaultMessage: 'No project yet',
                })}
                emptyDropDownStateDescription={intl.formatMessage({
                  id: 'createproject.workflow.projects.empty_state.description',
                  defaultMessage: 'To continue please add a project',
                })}
                emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                  id: 'createproject.workflow.projects.empty_state.link_button.label',
                  defaultMessage: 'Add Project',
                })}
                emptyDropDownStateLinkButtonPath={ROUTES.PROJECTS}
              />
            );
          }}
        />

        <SettingActionRow
          dataTestId={`${dataTestId}__create-new-project`}
          button={{
            iconName: 'add_0',
            onClick: handleCreateNewProject,
            children: (
              <FormattedMessage
                id="createproject.workflow.projects.add.new.button.label"
                defaultMessage="Create a new Project"
              />
            ),
          }}
        />
      </Fields>
    </>
  );
};

SelectProject.propTypes = {
  dataTestId: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleCreateNewProject: PropTypes.func.isRequired,
};

export default SelectProject;
