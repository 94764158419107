import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import React from 'react';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

const PostProcessorCEAD = ({ isSubmitting }) => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const intl = useIntl();
  return (
    <>
      <FormikField
        component={SettingCheckbox}
        dataTestId={`printer-custom-post-processor__setting-ceadEnablePurge`}
        checkbox={{
          disabled: isSubmitting,
          checked: Boolean(values['ceadEnablePurge']),
          onChange: () => {
            setFieldValue('ceadEnablePurge', !values['ceadEnablePurge']);
          },
          dirty: checkIfFieldIsDirty(values, initialValues, 'ceadEnablePurge'),
        }}
        name={'ceadEnablePurge'}
        label={intl.formatMessage({
          id: 'printers.postprocessor.cead.purge.label',
          defaultMessage: 'Enable Purge Sequence',
        })}
      />
      <FormikField
        component={SettingCheckbox}
        dataTestId={`printer-custom-post-processor__setting-ceadEnableTemperatures`}
        checkbox={{
          disabled: isSubmitting,
          checked: Boolean(values['ceadEnableTemperatures']),
          onChange: () => {
            setFieldValue(
              'ceadEnableTemperatures',
              !values['ceadEnableTemperatures'],
            );
          },
          dirty: checkIfFieldIsDirty(
            values,
            initialValues,
            'ceadEnableTemperatures',
          ),
        }}
        name={'ceadEnableTemperatures'}
        label={intl.formatMessage({
          id: 'printers.postprocessor.cead.temperature.label',
          defaultMessage: 'Enable Temperature Control Instructions',
        })}
      />
    </>
  );
};

PostProcessorCEAD.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorCEAD;
