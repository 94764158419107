import { printerConstants as constants } from '@constants/printers/printerConstants';

import getIntlProvider from '@app/utils/getIntlProvider';

const intl = getIntlProvider();

export const postProcessorSettingsDefinitions = {
  DEFAULT: {
    label: 'Default',
    value: 'DEFAULT',
  },
  CUSTOM: {
    ...constants.CUSTOM,
  },
};

export const postProcessorControlDefinitions = {
  CONSTANT: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.extrusion.control.constant.label',
    }),
    value: 'CONSTANT',
  },
  DYNAMIC: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.extrusion.control.dynamic.label',
    }),
    value: 'DYNAMIC',
  },
};

export const postProcessorCalculationDefinitions = {
  DEFAULT: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.extrusion.calculation.default.label',
    }),
    value: 'DEFAULT',
  },
  NOZZLE: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.extrusion.calculation.nozzle.label',
    }),
    value: 'NOZZLE',
  },
  AREA: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.extrusion.calculation.area.label',
    }),
    value: 'AREA',
  },
  VOLUME: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.extrusion.calculation.volume.label',
    }),
    value: 'VOLUME',
  },
};

export const postProcessorGCodeDefinitions = {
  NONE: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.gcode.none.label',
    }),
    value: 'NONE',
  },
  EULER_ZYX_INTRINSIC: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.gcode.euler.zyx.intrinsic.label',
    }),
    value: 'EULER_ZYX_INTRINSIC',
  },
  EULER_XYZ_INTRINSIC: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.gcode.euler.xyz.intrinsic.label',
    }),
    value: 'EULER_XYZ_INTRINSIC',
  },
  EULER_ZYX_EXTRINSIC: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.gcode.euler.zyx.extrinsic.label',
    }),
    value: 'EULER_ZYX_EXTRINSIC',
  },
  EULER_XYZ_EXTRINSIC: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.gcode.euler.xyz.extrinsic.label',
    }),
    value: 'EULER_XYZ_EXTRINSIC',
  },
  NORMAL_VECTOR: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.gcode.normal.vector.label',
    }),
    value: 'NORMAL_VECTOR',
  },
};

export const postProcessorActionTypes = {
  APPEND: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.action.append.label',
    }),
    value: 'APPEND',
  },
  REPLACE: {
    label: intl.formatMessage({
      id: 'printers.postprocessor.action.replace.label',
    }),
    value: 'REPLACE',
  },
};

export const postProcessorConfigType = {
  Header: {
    id: 'HEADER',
  },
  Footer: {
    id: 'FOOTER',
  },
  Layer: {
    id: 'LAYER',
  },
  LineType: {
    id: 'LINE_TYPE',
  },
  Extrusion: {
    id: 'EXTRUSION',
  },
  ExtrusionControl: {
    id: 'EXTRUSION_CONTROL',
  },
  ExtrusionCalculation: {
    id: 'EXTRUSION_CALCULATION',
  },
  ExtrusionRateChange: {
    id: 'EXTRUSION_RATE_CHANGE',
  },
  DepositionMultiplier: {
    id: 'DEPOSITION_MULTIPLIER',
  },
  MinimumDepositionValue: {
    id: 'DEPOSITION_VALUE_MINIMUM',
  },
  MaximumDepositionValue: {
    id: 'DEPOSITION_VALUE_MAXIMUM',
  },
  ToggleOn: {
    id: 'TOGGLE_ON',
  },
  ToggleOff: {
    id: 'TOGGLE_OFF',
  },
  GCodeRotationFormat: {
    id: 'GCODE_ROTATION_FORMAT',
  },
  IsToolChangingEnabled: {
    id: 'IS_TOOL_CHANGING_ENABLED',
  },
  //RETRACTION SETTINGS
  IsRetractionEnabled: {
    id: 'IS_RETRACTION_ENABLED',
  },
  UseRetractionCustom: {
    id: 'USE_RETRACTION_CUSTOM',
  },
  CustomRetractionInstructions: {
    id: 'CUSTOM_RETRACTION_INSTRUCTIONS',
  },
  CustomProtractionInstructions: {
    id: 'CUSTOM_PROTRACTION_INSTRUCTIONS',
  },
  //----
  //PROCESS PARAMETERS SETTINGS
  IsProcessParametersEnabled: {
    id: 'IS_PROCESS_PARAMETERS_ENABLED',
  },
  ProcessParametersIDsInstructions: {
    id: 'PROCESS_PARAMETERS_IDS_INSTRUCTIONS',
  },
  DefaultProcessParametersID: {
    id: 'DEFAULT_PROCESS_PARAMETERS_ID',
  },
  //----
  ToolChangeInstructions: {
    id: 'TOOL_CHANGE_INSTRUCTIONS',
  },
  ABBIsAnalogSignalEnabled: {
    id: 'ABB_IS_ANALOG_SIGNAL_ENABLED',
  },
  ABBAnalogSignalName: {
    id: 'EXTRUSION_RATE_ANALOG_SIGNAL_NAME',
  },
  IsToolExternalAxis: {
    id: 'IS_TOOL_EXTERNAL_AXIS',
  },
  ToolExternalAxisId: {
    id: 'TOOL_EXTERNAL_AXIS_ID',
  },
  CEADEnablePurge: {
    id: 'CEAD_ENABLE_PURGE',
  },
  CEADEnableTemperatures: {
    id: 'CEAD_ENABLE_TEMPERATURES',
  },
  UseJointPositions: {
    id: 'USE_JOINT_POSITIONS',
  },
};

export const postProcessorInstructionType = {
  InstructionSet: {
    id: 'INSTRUCTION_SET',
  },
  Setting: {
    id: 'SETTING',
  },
  SettingBoolean: {
    id: 'SETTING_BOOLEAN',
  },
};

export const postProcessorBooleanSetting = {
  TRUE: {
    label: 'True',
    value: 'true',
  },
  FALSE: {
    label: 'False',
    value: 'false',
  },
};

//Postprocessor Program Types
export const KUKA = 'KUKA';
export const KUKA_STREAMING = 'KUKA_STREAMING';
export const KUKA_ARCTECH = 'KUKA_ARCTECH';
export const KUKA_EXLABS = 'KUKA_EXLABS';
export const GCODE_PROGRAMS = ['GCODE', 'GENERATIVE_MACHINE'];
export const YASKAWA = 'YASKAWA';
export const ABB = 'ABB';
export const CEAD_PROGRAMS = [
  'CEAD_FLEXBOT',
  'CEAD_FLEXBOT_DFC',
  'CEAD_FLEXBOT_WEIR',
];

export const SUPPORTS_ADDITIONAL_CUSTOMISATIONS = [
  KUKA,
  KUKA_STREAMING,
  YASKAWA,
  ...GCODE_PROGRAMS,
  ABB,
  KUKA_ARCTECH,
  KUKA_EXLABS,
];
