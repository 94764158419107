import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import React from 'react';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import SettingsCategory from '@components/2-molecules/SettingsCategory';

const PostProcessorToolType = ({ isSubmitting }) => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const intl = useIntl();

  return (
    <SettingsCategory
      title={intl.formatMessage({
        id: 'printers.postprocessor.toolChange.label',
        defaultMessage: 'Enable Tool Changing',
      })}
      dataTestId={`printer-custom-post-processor__setting-isToolChangingEnabled`}
      endingCheckboxChecked={Boolean(values['isToolChangingEnabled'])}
      endingCheckboxDirty={checkIfFieldIsDirty(
        values,
        initialValues,
        'isToolChangingEnabled',
      )}
      onEndingCheckboxClick={() => {
        setFieldValue(
          'isToolChangingEnabled',
          !values['isToolChangingEnabled'],
        );
      }}
      expand={Boolean(values['isToolChangingEnabled'])}
    >
      <FormikField
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-extrusionRateChangeInstruction`}
        name={'toolChangeInstruction'}
        disabled={isSubmitting}
        label={intl.formatMessage({
          id: 'printers.postprocessor.toolChange.instructions.label',
        })}
        renderAsTextArea
        descriptionLabel={intl.formatMessage({
          id: 'printers.postprocessor.toolChange.description.label',
        })}
      />
    </SettingsCategory>
  );
};

PostProcessorToolType.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorToolType;
