import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import {
  postProcessorCalculationDefinitions,
  postProcessorControlDefinitions,
  postProcessorSettingsDefinitions,
} from '@constants/printers/postProcessorSettingsDefinitions';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import PropTypes from 'prop-types';

const PostProcessorExtrusion = ({ isSubmitting }) => {
  const { values } = useFormikContext();
  const intl = useIntl();

  const getFormattedOptions = useMemo(
    () => (definitions) =>
      Object.values(definitions).map((option) => ({
        label: option.label,
        formFieldValue: {
          label: option.label,
          value: option.value,
        },
      })),
    [],
  );

  const extrusionOptions = getFormattedOptions(
    postProcessorSettingsDefinitions,
  );
  const controlOptions = getFormattedOptions(postProcessorControlDefinitions);
  const calculationOptions = getFormattedOptions(
    postProcessorCalculationDefinitions,
  );

  const extrusionSelected = values.extrusionAction;
  const controlSelected = values.controlAction;

  const showContent =
    extrusionSelected &&
    extrusionSelected.value === postProcessorSettingsDefinitions.CUSTOM.value;

  const showCalculation =
    controlSelected &&
    controlSelected.value !== postProcessorControlDefinitions.CONSTANT.value;

  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        label={intl.formatMessage({
          id: 'printers.postprocessor.extrusion.label',
        })}
        dataTestId={`printer-custom-post-processor__setting-extrusionAction`}
        name={'extrusionAction'}
        dropDownMenuItems={extrusionOptions}
        disabled={isSubmitting}
      />
      {showContent && (
        <>
          <FormikField
            component={PostProcessorSetting}
            label={intl.formatMessage({
              id: 'printers.postprocessor.extrusion.control.label',
            })}
            dataTestId={`printer-custom-post-processor__setting-controlAction`}
            name={'controlAction'}
            dropDownMenuItems={controlOptions}
            disabled={isSubmitting}
          />
          <FormikField
            component={PostProcessorSetting}
            dataTestId={`printer-custom-post-processor__setting-toggleOnInstruction`}
            name={'toggleOnInstruction'}
            label={intl.formatMessage({
              id: 'printers.postprocessor.extrusion.toggle.on.label',
            })}
            disabled={isSubmitting}
            renderAsTextArea
            descriptionLabel={intl.formatMessage({
              id: 'printers.postprocessor.extrusion.toggle.on.description.label',
            })}
          />
          <FormikField
            component={PostProcessorSetting}
            dataTestId={`printer-custom-post-processor__setting-toggleOffInstruction`}
            name={'toggleOffInstruction'}
            label={intl.formatMessage({
              id: 'printers.postprocessor.extrusion.toggle.off.label',
            })}
            disabled={isSubmitting}
            renderAsTextArea
            descriptionLabel={intl.formatMessage({
              id: 'printers.postprocessor.extrusion.toggle.off.description.label',
            })}
          />
          {showCalculation && (
            <>
              <FormikField
                component={PostProcessorSetting}
                dataTestId={`printer-custom-post-processor__setting-calculationAction`}
                label={intl.formatMessage({
                  id: 'printers.postprocessor.extrusion.calculation.label',
                })}
                name={'calculationAction'}
                dropDownMenuItems={calculationOptions}
                disabled={isSubmitting}
              />
              <FormikField
                component={PostProcessorSetting}
                dataTestId={`printer-custom-post-processor__setting-extrusionRateChangeInstruction`}
                name={'extrusionRateChangeInstruction'}
                label={intl.formatMessage({
                  id: 'printers.postprocessor.extrusion.rate.change.label',
                })}
                disabled={isSubmitting}
                renderAsTextArea
                descriptionLabel={intl.formatMessage({
                  id: 'printers.postprocessor.extrusion.rate.change.description.label',
                })}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

PostProcessorExtrusion.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorExtrusion;
