import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Text, {
  TEXT_VARIANT_BODY_LARGE,
  TEXT_VARIANT_BODY_MEDIUM,
} from '@components/1-atoms/Text';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import IconButtonComponent from '@components/1-atoms/IconButton';
import {
  LIST_ITEM_DESCRIPTION_DOT_VARIANT_SUCCESS,
  LIST_ITEM_DESCRIPTION_DOT_VARIANT_ERROR,
  LIST_ITEM_DESCRIPTION_DOT_VARIANT_CAUTION,
} from './ListItem';
import { flexCenterVertical, flexColumn, when } from '@stylesheets/helpers';

export const SkeletonWrapper = styled.div`
  height: 56px;
  ${flexColumn}
  justify-content: center;
  flex-shrink: 0;

  ${({ theme: { spacing } }) => css`
    padding: 8px ${spacing.level5};
  `}
`;

export const Icon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  ${({ theme: { colors } }) => css`
    fill: ${colors.onSurface};
  `}
`;

export const ThumbnailContainer = styled.div`
  width: 56px;
  height: 56px;
  overflow: hidden;

  ${({ flex = true, thumbnailUrl, theme: { borderRadius, colors } }) => css`
    background-color: ${colors.surfaceDim};
    border-radius: ${borderRadius.small};

    ${when(
      flex,
      `
        ${flexCenterVertical}
        justify-content: center;
      `,
    )}

    ${when(
      thumbnailUrl,
      `
        background-image: url(${thumbnailUrl});
        background-size: cover;
        background-position: center;
      `,
    )}
  `}
`;

export const ThumbnailPlaceholderIcon = styled(Icon)`
  width: 40px;
  height: 40px;
  opacity: 0.38;

  ${({ theme: { colors } }) => css`
    fill: ${colors.onSurfaceVariant};
  `}
`;

export const Content = styled.div`
  ${flexColumn}
  flex: 1;
  justify-content: center;
  overflow: hidden;
`;

export const Label = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_LARGE,
  color: 'onSurface',
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 4px;
`;

export const DescriptionDot = styled.span`
  width: 11px;
  height: 11px;
  border-radius: 50%;

  ${({ dotVariant, theme: { colors } }) => css`
    background-color: ${colors.onSurfaceVariant};

    ${when(
      dotVariant === LIST_ITEM_DESCRIPTION_DOT_VARIANT_SUCCESS,
      `
        background-color: ${colors.success};
      `,
    )}

    ${when(
      dotVariant === LIST_ITEM_DESCRIPTION_DOT_VARIANT_ERROR,
      `
        background-color: ${colors.caution};
      `,
    )}

    ${when(
      dotVariant === LIST_ITEM_DESCRIPTION_DOT_VARIANT_CAUTION,
      `
        background-color: ${colors.error};
      `,
    )}
  `}
`;

export const Description = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'onSurfaceVariant',
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0.76;
`;

export const Wrapper = styled.div`
  ${flexCenterVertical}
  flex-shrink: 0;
  position: relative;
  user-select: none;
  overflow: hidden;

  ${({
    autoHeight,
    disabled,
    dragged,
    withThumbnail,
    withDescription,
    withAction,
    withEndingIconButton,
    theme: { spacing, colors, borderRadius },
  }) => css`
    gap: ${spacing.level5};
    padding: 8px ${spacing.level6} 8px ${spacing.level5};

    ${when(
      !autoHeight,
      `
        height: 56px;
      `,
    )}

    ${when(
      !autoHeight && (withDescription || withThumbnail),
      `
        height: 72px;
      `,
    )}

    ${when(
      withAction,
      `
        cursor: pointer;
      `,
    )}

    ${when(
      withEndingIconButton,
      `
        padding-right: ${spacing.level2};
      `,
    )}

    ${when(
      dragged,
      `
        border-radius: ${borderRadius.small};
        background-color: ${colors.surfaceContainerHighest};
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
      `,
    )}

    &:hover {
      ${when(
        withAction,
        `
          background-color: ${rgba(colors.onSurface, 0.08)};
        `,
      )}
    }

    &:focus:focus-visible {
      ${when(
        withAction,
        `
          background-color: ${rgba(colors.onSurface, 0.12)};
        `,
      )}
    }

    ${when(
      disabled,
      `
        opacity: 0.38;
        pointer-events: none;
      `,
    )}
  `}
`;

export const EndingIconButton = styled(IconButtonComponent)`
  position: relative;
  z-index: 0;
`;
