import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import React, { useCallback, useMemo } from 'react';
import { Formik, Field as FormikField } from 'formik';
import Field from '@components/1-atoms/Field';
import DropDownField from '@components/1-atoms/DropDownField';
import { Fields } from '@containers/Dialogs/EditUserDialog/EditUserDialog.styled';
import DialogPortal from '@components/2-molecules/DialogPortal';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useOrganizationMutations from '@hooks/organization/useOrganizationMutations';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import { UserRoles } from '@constants/userRoles';
import { createNameRoleAndUsernameValidationSchema } from '@utils/validationHelper';
import useSnackbar from '@hooks/useSnackbar';
import DatePickerField from '@components/1-atoms/DatePickerField';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@selectors/loginSelectors';

const MODAL_ID = ModalDataTypes.EDIT_USER;

const EditUserDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const { showSnackbar } = useSnackbar();
  const currentUser = useSelector(getCurrentUser());
  const { updateUserMutation } = useOrganizationMutations();

  const validationSchema = useMemo(
    () => createNameRoleAndUsernameValidationSchema(intl),
    [intl],
  );

  const formInitialValues = useMemo(
    () => ({
      name: dialogData?.user?.name,
      username: dialogData?.user?.username,
      role: {
        label: dialogData?.user?.role,
        value: dialogData?.user?.role,
      },
      expiryDate: dialogData?.user?.expiryDate,
    }),
    [dialogData],
  );

  const onFormSubmit = useCallback(
    async ({ name, role, username, expiryDate }) => {
      try {
        await updateUserMutation.mutateAsync({
          user: {
            editedUsername: username,
            displayName: name,
            role: role.value,
            organizationId: dialogData.user.organizationId,
            expiryDate: expiryDate,
          },
          username: dialogData.user.username,
          id: dialogData.user.id,
        });
        showSnackbar({
          text: intl.formatMessage({
            id: 'edituser.snackbar.label',
            defaultMessage: 'The user has been successfully updated',
          }),
        });
      } catch (_) {
        //error
      }
      hideDialog(MODAL_ID);
    },
    [showSnackbar, intl, dialogData, updateUserMutation, hideDialog],
  );

  const getUserRolesDropDownMenuItems = useCallback((values) => {
    return Object.keys(UserRoles).map((role) => ({
      label: role,
      selected: values?.role.value === role,
      formFieldValue: {
        label: role,
        value: role,
      },
    }));
  }, []);

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
        <DialogPortal
          renderAsForm
          dataTestId="edit-user"
          dialogId={MODAL_ID}
          loading={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={intl.formatMessage({
            id: 'general.save',
            defaultMessage: 'Save',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          <PageHeader
            variant={PAGE_HEADER_VARIANT_MEDIUM}
            title={intl.formatMessage({
              id: 'edituser.header.title',
              defaultMessage: 'Edit user',
            })}
          />

          <Fields>
            <FormikField
              component={Field}
              dataTestId="edit-user__name__field"
              disabled={isSubmitting}
              name="name"
              label={intl.formatMessage({
                id: 'createuser.name.label',
                defaultMessage: 'Name',
              })}
            />

            <FormikField
              component={Field}
              dataTestId="edit-user__username__field"
              disabled={isSubmitting}
              name="username"
              label={intl.formatMessage({
                id: 'createuser.username.label',
                defaultMessage: 'Email address',
              })}
            />

            <FormikField
              component={DropDownField}
              dataTestId="edit-user__role__dropdown-field"
              disabled={isSubmitting}
              name="role"
              value={values?.role.value}
              label={intl.formatMessage({
                id: 'createuser.role.label',
                defaultMessage: 'Select Role',
              })}
              dropDownMenuItems={getUserRolesDropDownMenuItems(values)}
            />

            {currentUser?.role === UserRoles.SUPER_ADMIN && (
              <FormikField
                component={DatePickerField}
                onChange={setFieldValue}
                dataTestId="edit-user__expiryDate__dropdown-field"
                disabled={isSubmitting}
                name="expiryDate"
                label={intl.formatMessage({
                  id: 'createuser.expiration.date.label',
                  defaultMessage: 'Expiration date',
                })}
              />
            )}
          </Fields>
        </DialogPortal>
      )}
    </Formik>
  );
};

EditUserDialog.propTypes = {};

export default EditUserDialog;
