import {
  getLastAddedOperatorId,
  getSelectedOperatorOutputId,
} from '@selectors/conceptSelectors';
import { checkInputValidity } from '@utils/validation';
import { omit } from 'lodash';
import { showErrorDialog } from '../actions/errorActions.js';
import getIntlProvider from '@app/utils/getIntlProvider';
import {
  CHANGE_CURRENT_FOCUS_MODEL,
  ENTER_TOOLPATH_SIMULATION,
  EXIT_TOOLPATH_SIMULATION,
  FETCH_TOOLPATH_SIMULATION,
  FETCH_TOOLPATH_SIMULATION_FAILED,
  FETCH_TOOLPATH_SIMULATION_SUCCEEDED,
  FREEZE_OPERATOR_OUTPUT,
  OPRATOR_SELECTOR_DRAG_END,
  OPRATOR_SELECTOR_DRAG_START,
  RESET_CONCEPT_MODIFICATIONS,
  RESET_FROZEN_OPERATOR_OUTPUTS,
  RESET_LAST_ADDED_OPERATOR_ID,
  SELECT_OPERATOR_OUTPUT,
  SET_TOOLPATH_SIMULATION,
  SET_TOOLPATH_SIMULATION_VISIBILITY,
  TOGGLE_SOLID_VISIBILITY,
  UNFREEZE_OPERATOR_OUTPUT,
  UPDATE_BRIM_LINE_VISIBILITY,
  UPDATE_CAMERA,
  UPDATE_CAMERA_POSITION,
  UPDATE_COMPUTE_AUTOMATICALLY,
  UPDATE_CONCEPT,
  UPDATE_CONCEPT_FAILED,
  UPDATE_CONCEPT_SUCCEEDED,
  UPDATE_DIMENSION_VISIBILITY,
  UPDATE_DIRECTION_VISIBILITY,
  UPDATE_DISPLAY_MODE,
  UPDATE_ENCLOSURE_VISIBILITY,
  UPDATE_GRID_VISIBILITY,
  UPDATE_INFILL_LINE_VISIBILITY,
  UPDATE_INNER_WALL_LINE_VISIBILITY,
  UPDATE_LAST_ADDED_OPERATOR_ID,
  UPDATE_LATTICE_LINE_VISIBILITY,
  UPDATE_LEGEND_RANGE,
  UPDATE_LINETYPE_VISIBILITY,
  UPDATE_OUTER_WALL_LINE_VISIBILITY,
  UPDATE_MILLING_LINE_VISIBILITY,
  UPDATE_PRINTINGBED_VISIBILITY,
  UPDATE_ROBOT_VISIBILITY,
  UPDATE_ROTATIONS_VISIBILITY,
  UPDATE_SEAMS_VISIBILITY,
  UPDATE_SIMULATION_TRAVELLINE_VISIBILITY,
  UPDATE_SKIN_LINE_VISIBILITY,
  UPDATE_SUPPORT_INTERFACE_LINE_VISIBILITY,
  UPDATE_SUPPORT_LINE_VISIBILITY,
  UPDATE_VERTICES_VISIBILITY,
  UPDATE_WORKSPACE_VISIBILITY,
} from '../constants/actionTypes.js';
import { GET, PUT } from '../constants/fetchMethods.js';
import {
  GEOMETRY_CLASSIFIED_POLYLINES,
  GEOMETRY_PLANES,
  GEOMETRY_POINTS,
  GEOMETRY_TRIANGLE_MESH,
} from '../constants/operatorOutputTypes.js';
import { PRINTER } from '../constants/operatorValues.js';
import { TOOLPATH_INSTRUCTIONS_OUT_TYPE } from '../constants/operatorsConstants.js';
import { loadPrintingObject } from '../utils/model';
import { AuthenticatedFetch } from './AuthenticatedFetch.js';
import { fetchToolpathOperatorInstructions } from './toolpathActions.js';
const intl = getIntlProvider();

export const resetConceptModifications = (conceptId) => ({
  type: RESET_CONCEPT_MODIFICATIONS,
  payload: { conceptId },
});

export const updateConcept =
  (conceptId, options = {}) =>
  (dispatch, getState) => {
    const { concepts, workspaces } = getState();
    const concept =
      options?.workflow ||
      concepts.data.concepts.find(({ id }) => id === conceptId);
    if (!concept) return null;

    const {
      operatorModifications,
      operatorInputModifications,
      unsavedModifications,
    } = concepts.ui;

    const updatedPrinterId = unsavedModifications[concept.id]?.printerId;

    const updatedOperators = concept.operators.map((operator) => {
      const updatedValues = operator.values.map((valueProps) => {
        const modifiedValueProps = operatorInputModifications.find(
          ({ id }) => valueProps.id === id,
        );
        if (
          valueProps.type === PRINTER &&
          updatedPrinterId &&
          updatedPrinterId !== concept.printerId
        ) {
          if (modifiedValueProps) modifiedValueProps.value = updatedPrinterId;
          else valueProps.value = updatedPrinterId;
        }

        const result = Object.assign({}, valueProps, {
          value: (modifiedValueProps || valueProps).value,
        });
        delete result.inputTemplateId;
        delete result.locked;
        delete result.exposed;
        delete result.triggerComputation;
        return result;
      });

      const modifiedOperator = operatorModifications.find(
        ({ id }) => id === operator.id,
      );
      const clonedOperator = Object.assign({}, operator, {
        tag: (modifiedOperator || operator).tag,
        values: updatedValues,
      });
      delete clonedOperator.operatorDescriptor;
      delete clonedOperator.nameKey;
      delete clonedOperator.translatedName;
      delete clonedOperator.deprecated;
      delete clonedOperator.upgradable;
      delete clonedOperator.computed;
      delete clonedOperator.iconUrl;
      return clonedOperator;
    });

    const updatedConcept = Object.assign(
      {},
      {
        printerId: concept.printerId,
        nozzleId: concept.nozzleId,
        materialId: concept.materialId,
        operators: updatedOperators,
        workspaceId:
          options?.workflow?.workspaceId ||
          workspaces.data.selectedWorkspace.id,
      },
      unsavedModifications[concept.id] || {},
    );

    updatedConcept.hiddenInputs = concepts.ui.hiddenInputs.map((item) => ({
      ...omit(item, [
        'inputTemplateId',
        'locked',
        'exposed',
        'triggerComputation',
      ]),
    }));

    return dispatch(
      AuthenticatedFetch({
        url: `concepts/${conceptId}`,
        body: JSON.stringify(updatedConcept),
        method: PUT,
        prefetchAction: {
          type: UPDATE_CONCEPT,
        },
        successAction: (responseBody) => () => {
          dispatch({
            type: UPDATE_CONCEPT_SUCCEEDED,
            payload: responseBody,
          });
          dispatch(resetConceptModifications(conceptId));
        },
        failureAction: () => ({
          type: UPDATE_CONCEPT_FAILED,
        }),
        failureCallback: options?.failureCallback,
      }),
    );
  };

export const selectOperatorOutput = (output) => ({
  type: SELECT_OPERATOR_OUTPUT,
  payload: {
    id: output?.id,
    outputType: output?.type,
    operatorId: output?.operatorId,
    outputValue: output?.value,
    output,
  },
});

export const freezeOperatorOutput = (output) => ({
  type: FREEZE_OPERATOR_OUTPUT,
  payload: {
    output,
  },
});

export const unfreezeOperatorOutput = (outputIds) => ({
  type: UNFREEZE_OPERATOR_OUTPUT,
  payload: {
    ids: outputIds,
  },
});

export const resetFrozenOperatorOutputs = () => ({
  type: RESET_FROZEN_OPERATOR_OUTPUTS,
});

export const updateCamera = (doUpdate) => ({
  type: UPDATE_CAMERA,
  payload: {
    doUpdate,
  },
});

export const displayComputedOutput = (workflow) => (dispatch, getState) => {
  const state = getState();
  const lastAddedOperatorId = getLastAddedOperatorId(state);
  const selectedOperatorOutputId = getSelectedOperatorOutputId()(state);
  const { hiddenInputNames } = state.workflow;

  const operators = workflow?.operators || [];

  let targetOperator;

  if (lastAddedOperatorId) {
    targetOperator = operators?.find(
      (operator) => operator.id === lastAddedOperatorId,
    );
  }

  if (!targetOperator) {
    targetOperator = operators?.find((operator) =>
      operator?.values?.some((value) => value?.id === selectedOperatorOutputId),
    );
  }

  if (!targetOperator) {
    targetOperator = operators.at(-1);
  }

  const targetOperatorOutputs = (targetOperator?.values || [])?.filter(
    ({ name, isinput }) =>
      !isinput &&
      !hiddenInputNames[targetOperator.id].some(
        (hiddenInputName) => hiddenInputName === name,
      ),
  );

  const targetOperatorGeoOutput = targetOperatorOutputs.find(({ type }) =>
    [
      GEOMETRY_TRIANGLE_MESH,
      GEOMETRY_CLASSIFIED_POLYLINES,
      GEOMETRY_PLANES,
      GEOMETRY_POINTS,
    ].includes(type),
  );
  const targetOutput = targetOperatorGeoOutput || targetOperatorOutputs[0];

  if (!targetOutput || !targetOperator?.computed) return;

  dispatch(selectOperatorOutput(targetOutput));
  dispatch(resetLastAddedOperator());

  if (targetOutput.type !== TOOLPATH_INSTRUCTIONS_OUT_TYPE) return;

  dispatch(fetchToolpathOperatorInstructions(targetOperator.id));

  dispatch(
    fetchToolpathSimulation(
      targetOutput.operatorId,
      (toolpathSimulationUrl, toolpathSimulationPrinterStep) =>
        loadPrintingObject(toolpathSimulationUrl).then((glb) => {
          dispatch(enterToolpathSimulation(glb, toolpathSimulationPrinterStep));
        }),
    ),
  );
};

export const fetchToolpathInstructionsIfSelected =
  () => (dispatch, getState) => {
    const state = getState();
    const selectedOutputType = state.concepts.ui.selectedOperatorOutputType;
    const selectedOutputOperatorId = state.concepts.ui.selectedOutputOperatorId;
    if (selectedOutputType === TOOLPATH_INSTRUCTIONS_OUT_TYPE) {
      dispatch(fetchToolpathOperatorInstructions(selectedOutputOperatorId));
    }
  };

/**
 *
 * Find default operator from given operator
 * Find default input from given input
 * Get up to dated input value
 * Range should be check only in two conditions
 * 1. If input has two integer in allowed values
 * 2. If input type is SELECTION
 * Function returns true these three conditions are met:
 * 1. If input type is SELECTION, and input value is in the allowed values
 * 2. If input has two integer in allowed values and input value is in this range
 * 3. If input type is not SELECTION and input doesn't have two integer in allowed values
 * Function returns false in these two conditions:
 * 1. If input type is SELECTION, and input value is not in the allowed values
 * 2. If input has two integer in allowed values and input value is not in this range
 *
 * @param defaultOperators
 * @param operator
 * @param input
 * @param operatorInputModifications
 * @returns boolean
 */
export function isValueInValidRange(
  defaultOperators,
  operator,
  input,
  currentInputValue = '',
) {
  const { hasInterval, hasValidRange, defaultOperatorSetting, isSelection } =
    checkInputValidity(defaultOperators, operator, input.name);

  let isRangeValid = !hasValidRange;
  if (hasValidRange) {
    // get up to dated values for the operator
    const currentItemValue = currentInputValue || input?.value;
    if (hasInterval) {
      const min = parseFloat(defaultOperatorSetting.allowedValues[0]);
      const max = parseFloat(defaultOperatorSetting.allowedValues[1]);
      const currentValue = parseFloat(currentItemValue);
      return currentValue <= max && currentValue >= min;
    }

    if (isSelection) {
      const currentValue = currentItemValue;
      const allowedValues = defaultOperatorSetting.allowedValues;
      isRangeValid = allowedValues.includes(currentValue);
    }
  }
  return isRangeValid;
}

export const updateOuterWallLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_OUTER_WALL_LINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateMillingLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_MILLING_LINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateInnerWallLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_INNER_WALL_LINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateSupportLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_SUPPORT_LINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateSupportInterfaceLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_SUPPORT_INTERFACE_LINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateBrimLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_BRIM_LINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateSkinLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_SKIN_LINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateInfillLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_INFILL_LINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateLatticeLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_LATTICE_LINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateSeamsVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_SEAMS_VISIBILITY,
  });
  return Promise.resolve();
};

export const toggleSolidVisibility = () => ({ type: TOGGLE_SOLID_VISIBILITY });

export const updateRotationsVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_ROTATIONS_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateVerticesVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_VERTICES_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateDirectionVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_DIRECTION_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateDimensionVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_DIMENSION_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateGridVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_GRID_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateRobotVisibility =
  (value = undefined) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_ROBOT_VISIBILITY,
      payload: {
        enabled: value,
      },
    });
    return Promise.resolve();
  };

export const updatePrintingBedVisibility =
  (value = undefined) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_PRINTINGBED_VISIBILITY,
      payload: {
        enabled: value,
      },
    });
    return Promise.resolve();
  };

export const updateWorkspaceVisibility =
  (value = undefined) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_WORKSPACE_VISIBILITY,
      payload: {
        enabled: value,
      },
    });
    return Promise.resolve();
  };

export const updateEnclosureVisibility =
  (value = undefined) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_ENCLOSURE_VISIBILITY,
      payload: {
        enabled: value,
      },
    });
    return Promise.resolve();
  };

export const updateDisplayMode = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_DISPLAY_MODE,
    payload: {
      mode: value,
    },
  });
  return Promise.resolve();
};

export const updateLegendRange = (min, max) => (dispatch) => {
  dispatch({
    type: UPDATE_LEGEND_RANGE,
    payload: {
      minRange: min,
      maxRange: max,
    },
  });
  return Promise.resolve();
};

export const updateLinetypeVisibility = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_LINETYPE_VISIBILITY,
    payload: {
      mode: value,
    },
  });
  return Promise.resolve();
};

export const updateCameraPosition = (px, py, pz) => (dispatch) => {
  dispatch({
    type: UPDATE_CAMERA_POSITION,
    payload: {
      cameraX: px,
      cameraY: py,
      cameraZ: pz,
    },
  });
  dispatch(updateCamera(true));
};

export const updateComputeAutomatically = () => (dispatch) => {
  dispatch({
    type: UPDATE_COMPUTE_AUTOMATICALLY,
  });
};

export const changeCurrentFocusModel = (model) => (dispatch) => {
  dispatch({
    type: CHANGE_CURRENT_FOCUS_MODEL,
    payload: {
      focusmodel: model,
    },
  });
  return Promise.resolve();
};

// callback is for update printing object
export const fetchToolpathSimulation =
  (operatorId, callback, organizationIdOverride = null) =>
  (dispatch) =>
    dispatch(
      AuthenticatedFetch({
        url:
          `concepts/${operatorId}/simulation` +
          (organizationIdOverride
            ? '?organizationId=' + organizationIdOverride
            : ''),
        method: GET,
        disableErrorDialog: true,
        prefetchAction: {
          type: FETCH_TOOLPATH_SIMULATION,
        },
        successCallback: async (responseBody) => {
          if (callback) {
            await callback(
              responseBody.toolpathSimulation,
              responseBody.printerSteps,
            );
          }

          dispatch({
            type: FETCH_TOOLPATH_SIMULATION_SUCCEEDED,
          });
        },
        failureCallback: () => {
          dispatch(
            showErrorDialog(
              intl.formatMessage({
                id: 'fetch.simulation.failed.title',
                defaultMessage: 'Unable to simulate',
              }),
              '',
              'fetch.simulation.failed.message',
            ),
          );
        },
        failureAction: () => ({
          type: FETCH_TOOLPATH_SIMULATION_FAILED,
        }),
      }),
    );

export const enterToolpathSimulation =
  (printingObject, printerSteps) => (dispatch) => {
    const maxStepNumber = printerSteps.length - 1;
    const maxTime = printerSteps[maxStepNumber].time;
    const printingObjectCoordinates = [];
    let layerIndex = 0;
    printingObject.scene.children.forEach((mesh) => {
      const parsedMesh = JSON.parse(
        JSON.stringify(mesh.geometry.attributes.position.array),
      );

      parsedMesh.simulation = true;

      printingObjectCoordinates[layerIndex++] = parsedMesh;
    });

    dispatch(exitToolpathSimulation());
    dispatch({
      type: ENTER_TOOLPATH_SIMULATION,
      payload: {
        printingObject,
        maxStepNumber,
        printerSteps,
        maxTime,
        printingObjectCoordinates,
      },
    });
    return Promise.resolve();
  };

export const exitToolpathSimulation = () => (dispatch) => {
  dispatch({
    type: EXIT_TOOLPATH_SIMULATION,
  });
  return Promise.resolve();
};

export const setToolpathSimulation = (time) => ({
  type: SET_TOOLPATH_SIMULATION,
  payload: time,
});

export const updateSimulationTravelLineVisibility = () => (dispatch) => {
  dispatch({
    type: UPDATE_SIMULATION_TRAVELLINE_VISIBILITY,
  });
  return Promise.resolve();
};

export const updateLastAddedOperator = (operatorId = '') => ({
  type: UPDATE_LAST_ADDED_OPERATOR_ID,
  payload: { operatorId },
});

export const resetLastAddedOperator = () => ({
  type: RESET_LAST_ADDED_OPERATOR_ID,
});

export const setToolpathSimulationVisibility = (visible) => ({
  type: SET_TOOLPATH_SIMULATION_VISIBILITY,
  payload: { visible },
});

export const startOperatorSelectorDrag = (operatorId = '') => ({
  type: OPRATOR_SELECTOR_DRAG_START,
  payload: { operatorId },
});

export const endOperatorSelectorDrag = () => ({
  type: OPRATOR_SELECTOR_DRAG_END,
});
