import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { generatePath } from 'react-router-dom';
import usePrintingStatus from '@hooks/printers/usePrintingStatus';
import usePrinterSocket from '@hooks/websocket/usePrinterSocket';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import useOperatorMutations from '@hooks/operators/useOperatorMutations';
import { getProjectInstructionsURL } from '@selectors/conceptSelectors';
import { getCurrentUser } from '@selectors/loginSelectors';
import { fileExtensions } from '@constants/programFileTypes';
import { ROUTES } from '@constants/router';
import { sendEvent, EventCategory, EventAction } from '@utils/GaTracker';
import Paper from '@components/1-atoms/Paper';
import Button, {
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_TEXT,
  BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/Button';
import IconButton, {
  ICON_BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/IconButton';
import Loader, {
  LOADER_ICON_ANIMATION_VARIANT_PULSE,
} from '@components/2-molecules/Loader';
import { Content, LoaderWrapper } from './WorkflowToolpathBar.styled';
import { KUKA_STREAMING } from '../../constants/printers/postProcessorSettingsDefinitions';

const WorkflowToolpathBar = () => {
  const [showPrintingProgress, setShowPrintingProgress] = useState(false);
  const projectInstructionsURL = useSelector(getProjectInstructionsURL);
  const user = useSelector(getCurrentUser());
  const { getSelectedWorkflow, getWorkflowPrinter, getIsWorkflowEditable } =
    useWorkflow();
  const { getSelectedOperator, unselectSelectedOperator } = useOperator();
  const { streamToolpathOperatorToPrinterMutation } = useOperatorMutations();

  const workflow = getSelectedWorkflow();
  const printer = getWorkflowPrinter(workflow);
  const printerId = printer?.id;
  const selectedOperator = getSelectedOperator();
  const selectedOperatorId = selectedOperator?.id;
  const isWorkflowEditable = getIsWorkflowEditable();
  const {
    isPrinting,
    printerStatus,
    formattedPrinterStatusName,
    printingProgress,
    stopPrint,
  } = usePrintingStatus(printerId);

  usePrinterSocket();

  const downloadFile = useCallback((url = '', filename = '') => {
    if (!url) return;

    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => fileDownload(res?.data, filename));
  }, []);

  const downloadToolpath = useCallback(() => {
    const extension = fileExtensions[printer?.programType]
      ? fileExtensions[printer?.programType]
      : '.src';

    if (!projectInstructionsURL) return;

    let fileName = `${workflow?.name}.${
      selectedOperator?.tag || 'toolpath'
    }${extension}`;

    if (printer?.programType === KUKA_STREAMING) {
      fileName = `Toolpath${extension}`;
    }

    sendEvent(
      EventAction.toolpathDownloaded,
      EventCategory.engagement,
      fileName,
      user?.username,
    );

    downloadFile(projectInstructionsURL, fileName);
  }, [
    user?.username,
    workflow?.name,
    selectedOperator?.tag,
    printer?.programType,
    projectInstructionsURL,
    downloadFile,
  ]);

  const isComputationStreamable = useCallback(() => {
    if (!printerStatus) return false;

    const isIdle = printerStatus.status === 'IDLE';
    const currentDate = new Date();
    // TODO store the cutoff interval somewhere else
    const cutoffDate = new Date(currentDate.getTime() - 5000);
    const isStreamable = isIdle && new Date(printerStatus.time) > cutoffDate;

    return isStreamable;
  }, [printerStatus]);

  const stream = useCallback(async () => {
    sendEvent(
      EventAction.toolpathStreamed,
      EventCategory.engagement,
      selectedOperatorId,
      user?.username,
    );

    const streamResponse =
      await streamToolpathOperatorToPrinterMutation.mutateAsync({
        printerId: printerId,
        operatorId: selectedOperatorId,
      });

    if (!streamResponse?.printId) return;

    const navigationPath = generatePath(ROUTES.PRINTER, {
      printerId: printerId,
    });

    const win = window.open(navigationPath, '_blank');

    win.focus();
  }, [
    user?.username,
    printerId,
    selectedOperatorId,
    streamToolpathOperatorToPrinterMutation,
  ]);

  const togglePrintingProgress = useCallback(() => {
    setShowPrintingProgress((prev) => !prev);
  }, []);

  const stopPrinting = useCallback(() => {
    stopPrint();
    togglePrintingProgress();
  }, [stopPrint, togglePrintingProgress]);

  if (showPrintingProgress) {
    return (
      <LoaderWrapper>
        <Loader
          endingIconButtonIconName="stop_player_1"
          endingIconButtonTitle="Stop Printing"
          iconColor="success"
          iconName="Dot_1"
          iconAnimationVariant={LOADER_ICON_ANIMATION_VARIANT_PULSE}
          leadingIconButtonIconName="chevron_left_0"
          onEndingIconButtonClick={stopPrinting}
          onLeadingIconButtonClick={togglePrintingProgress}
          progress={printingProgress}
          title={formattedPrinterStatusName}
        />
      </LoaderWrapper>
    );
  }

  return (
    <Paper dataTestId="workflow-toolpath-bar">
      <Content withContent={!!projectInstructionsURL}>
        {projectInstructionsURL && (
          <>
            <Button
              disabled={!isWorkflowEditable}
              variant={BUTTON_VARIANT_TEXT}
              iconName="download_0"
              stretch={false}
              onClick={downloadToolpath}
            >
              <FormattedMessage
                id="operator.progress.download_toolpath"
                defaultMessage="Download Toolpath"
              />
            </Button>

            {isComputationStreamable() && (
              <Button
                variant={BUTTON_VARIANT_PRIMARY}
                stretch={false}
                onClick={stream}
              >
                <FormattedMessage id="general.stream" defaultMessage="Stream" />
              </Button>
            )}

            {isPrinting && (
              <Button
                variant={BUTTON_VARIANT_PLAIN}
                iconName="Dot_1"
                iconColor="success"
                stretch={false}
                onClick={togglePrintingProgress}
              >
                {formattedPrinterStatusName}
              </Button>
            )}
          </>
        )}

        <IconButton
          variant={ICON_BUTTON_VARIANT_PLAIN}
          iconName="close_0"
          onClick={unselectSelectedOperator}
        />
      </Content>
    </Paper>
  );
};

WorkflowToolpathBar.propTypes = {};

export default WorkflowToolpathBar;
