import React, { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { tokenAuthentication } from '@actions/loginActions';
import { Wrapper } from './TokenAuthenticator.styled';

export default function AuthChecker() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const proceedWithNavigation = useCallback(() => {
    history?.replace(location?.pathname);
  }, [history, location?.pathname]);

  useEffect(() => {
    dispatch(tokenAuthentication(proceedWithNavigation));
  }, [dispatch, proceedWithNavigation]);

  return <Wrapper />;
}
