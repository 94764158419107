import axios from 'axios';
import config from './config';
import environments from '@utils/environment';

const TIMEOUT = 120000;

const api = config[environments.current];

// if (process?.env?.REACT_APP_USE_TEST_ENV_SERVER) {
//   api.baseUrl = config.test.baseUrl;
//   api.baseUrlws = config.test.baseUrlws;
// }

const client = axios.create({
  baseURL: api.baseUrl,
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default client;
