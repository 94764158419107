import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { concat, isEmpty, isEqual, isUndefined, omit, pick } from 'lodash';
import usePrinter from '@hooks/printers/usePrinter';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useFile from '@hooks/files/useFile';
import useDialog from '@hooks/useDialog';
import { operatorsQueryKeys } from '@hooks/operators/useOperatorQueries';
import EnglishTranslation from '@app/languages/en-GB.json';
import useOrganization from '@hooks/organization/useOrganization';
import useOperatorMutations, {
  operatorMutationKeys,
} from '@hooks/operators/useOperatorMutations';
import { isFeatureEnabled } from '@selectors/configSelectors';
import {
  getFrozenOutputIds,
  getSelectedOperatorOutput,
  getSelectedOperatorOutputId,
} from '@selectors/conceptSelectors';
import { getComputationProgressHandler } from '@selectors/computationProgressSelectors';
import { showErrorDialog } from '@actions/errorActions';
import {
  enterToolpathSimulation,
  exitToolpathSimulation,
  fetchToolpathSimulation,
  selectOperatorOutput as selectOperatorOutputAction,
} from '@actions/conceptActions';
import { fetchToolpathOperatorInstructions } from '@actions/toolpathActions';
import {
  selectActiveCanvasSelectionInput,
  selectHiddenInputNames,
} from '@reducers/workflowSlice';
import { fileTypesByInputType, loadPrintingObject } from '@utils/model';
import {
  DROP_DOWN_DATA_FIELD_TYPES,
  DROP_DOWN_FILE_FIELD_TYPES,
  DROP_DOWN_INPUT_FIELD_TYPES,
  GEO_OUTPUT_TYPES,
  VISUALLY_HIDDEN_OUTPUT_TYPES,
  TOOLPATH_OUTPUT_TYPES,
} from '@constants/operatorFieldTypes';
import { OperatorInputTypes } from '@constants/operatorInputTypes';
import { HIDDEN_INPUT_TYPES } from '@constants/utilityConstants';
import { QUICK_START_FEATURE } from '@constants/features';
import { ModalDataTypes } from '@constants/modalDataTypes';
import {
  MELTIO_TOOLPATH_GENERATOR_OPERATOR_NAME,
  TOOLPATH_GENERATOR_OPERATOR_NAME,
} from '@constants/operatorsConstants';
import {
  OPERATOR_HEADER_STATUS_ALERT,
  OPERATOR_HEADER_STATUS_ALERT_MISS,
  OPERATOR_HEADER_STATUS_HIGHLIGHT,
} from '@utils/operatorStatus';
import { buildGraph } from '@utils/visualTool';
import useCanvasSelection from '@hooks/canvasselection/useCanvasSelection';
import useOperatorUtils from '@hooks/operators/useOperatorUtils';

export default function useOperator() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { showDialog } = useDialog();
  const { getIsOrganizationIdOverride } = useOrganization();
  const workflowHiddenInputNames = useSelector(selectHiddenInputNames);
  const selectedOperatorOutputId = useSelector(getSelectedOperatorOutputId());
  const selectedOperatorOutput = useSelector(getSelectedOperatorOutput());
  const activeCanvasSelectionInput = useSelector(
    selectActiveCanvasSelectionInput,
  );
  const organizationIdOverride = getIsOrganizationIdOverride();
  const frozenOutputIds = useSelector(getFrozenOutputIds());
  const currentComputationProgress = useSelector(getComputationProgressHandler);
  const quickStartFeatureAvailable = useSelector(
    isFeatureEnabled(QUICK_START_FEATURE),
  );
  const { getPrinters } = usePrinter();
  const { getOperatorFromProvidedArg, getDefaultOperators, getOperator } =
    useOperatorUtils();
  const {
    getSelectedWorkflow,
    getSelectedWorkflowOperators,
    getSelectedWorkflowProjectId,
    getIsWorkflowComputing,
  } = useWorkflow();
  const { doesFieldSupportCanvasSelection, doesOutputSupportCanvasSelection } =
    useCanvasSelection();
  const { getProjectFiles } = useFile();
  const { explodeOperatorMutation } = useOperatorMutations();
  const selectedWorkflow = getSelectedWorkflow();
  const selectedWorkflowId = selectedWorkflow?.id;

  const asyncExplodeOperatorMutation = explodeOperatorMutation.mutateAsync;

  const getIsAddingOperator = useCallback(
    () =>
      queryClient.isMutating({ mutationKey: operatorMutationKeys.addOperator }),
    [queryClient],
  );

  const getIsDeletingOperator = useCallback(
    () =>
      queryClient.isMutating({
        mutationKey: operatorMutationKeys.deleteOperator,
      }),
    [queryClient],
  );

  const getIsReorderingOperator = useCallback(
    () =>
      queryClient.isMutating({
        mutationKey: operatorMutationKeys.reorderOperator,
      }),
    [queryClient],
  );

  const getInputsVisibilityConditions = useCallback(
    () =>
      queryClient.getQueryData(
        operatorsQueryKeys.defaultOperators(selectedWorkflowId),
      )?.inputsVisibilityConditions,
    [queryClient, selectedWorkflowId],
  );

  const getNormalizedDefaultOperators = useCallback(
    () =>
      queryClient.getQueryData(
        operatorsQueryKeys.defaultOperators(selectedWorkflowId),
      )?.normalizedDefaultOperators,
    [queryClient, selectedWorkflowId],
  );

  const getDefaultOperator = useCallback(
    (operatorName) => getNormalizedDefaultOperators()?.[operatorName],
    [getNormalizedDefaultOperators],
  );

  const getDefaultOperatorSettings = useCallback(
    (operatorName) => getDefaultOperator(operatorName)?.settings,
    [getDefaultOperator],
  );

  const getComputedOperators = useCallback(() => {
    const workflowOperators = getSelectedWorkflowOperators();
    const computedOperators = workflowOperators.filter(
      (operator) => operator.computed,
    );

    return computedOperators;
  }, [getSelectedWorkflowOperators]);

  const getTemplates = useCallback(
    () => queryClient.getQueryData(operatorsQueryKeys.templates),
    [queryClient],
  );

  const getWorkflowRecommendedOperators = useCallback(
    (workflowId) =>
      queryClient.getQueryData(
        operatorsQueryKeys.recommendedOperators(workflowId),
      ),
    [queryClient],
  );

  const getIsTemplateOperator = useCallback(
    (operator = {}) => !!operator?.templateId,
    [],
  );

  const getIsOperatorComputed = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const isComputed = operator.computed;

      return isComputed;
    },
    [getOperatorFromProvidedArg],
  );

  const getIsOperatorComputing = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const isComputing =
        getIsWorkflowComputing() &&
        currentComputationProgress?.id === operator?.id;

      return isComputing;
    },
    [
      currentComputationProgress,
      getIsWorkflowComputing,
      getOperatorFromProvidedArg,
    ],
  );

  const getIsOperatorExplodable = useCallback(
    (operatorOrId) => {
      const printers = getPrinters();
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const quickStartAvailable =
        printers?.length && quickStartFeatureAvailable;
      const explodable = quickStartAvailable && !!operator?.templateId;

      return explodable;
    },
    [getPrinters, getOperatorFromProvidedArg, quickStartFeatureAvailable],
  );

  const getAllOperatorInputs = useCallback(
    (operatorOrId) => getOperatorFromProvidedArg(operatorOrId)?.values,
    [getOperatorFromProvidedArg],
  );

  const getOperatorInputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator?.values?.filter(({ isinput }) => isinput) || [];
    },
    [getOperatorFromProvidedArg],
  );

  const getInput = useCallback(
    (inputId) => {
      const operatorsToIterate = getSelectedWorkflowOperators();
      let targetInput;

      operatorsToIterate.forEach((operator) => {
        const input = operator?.values?.find(({ id }) => id === inputId);

        if (input) {
          targetInput = input;
        }
      });

      return targetInput;
    },
    [getSelectedWorkflowOperators],
  );

  const getOperatorInput = useCallback(
    (operatorOrId, inputId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator?.values?.find(({ id }) => id === inputId);
    },
    [getOperatorFromProvidedArg],
  );

  const getOperatorOutputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator?.values?.filter(({ isinput }) => !isinput) || [];
    },
    [getOperatorFromProvidedArg],
  );

  const calculateOperatorHiddenInputs = useCallback(
    (operatorOrId, { formValues = {}, normalizeOutput = false } = {}) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorInputs = getOperatorInputs(operator);
      const files = getProjectFiles(getSelectedWorkflowProjectId());
      const visibilityConditions = getInputsVisibilityConditions();
      const operatorConditions = visibilityConditions?.[operator.name];

      const inputNameConditions = operatorInputs.reduce((acc, input) => {
        const inputName = input.name;
        const inputValue = formValues?.[input.id] || input.value;

        return {
          ...acc,
          [inputName]: `${inputValue}`,
        };
      }, {});

      const parseCondition = (condition = '') => {
        const parsedCondition = condition.split(':');
        const conditionInputName = parsedCondition?.[0];
        let conditionValue = parsedCondition?.[1];
        let compareValueExtension = false;

        if (conditionValue?.includes('*.')) {
          compareValueExtension = true;
          conditionValue = conditionValue.replace('*.', '');
        }

        return { conditionInputName, conditionValue, compareValueExtension };
      };

      const matchCondition = (condition) => {
        const { conditionInputName, conditionValue, compareValueExtension } =
          parseCondition(condition);
        const fieldValue = inputNameConditions?.[conditionInputName];

        if (compareValueExtension) {
          const file = files.find((file) => file.id === fieldValue);

          return file?.filetype === conditionValue.toLowerCase();
        }

        return !isUndefined(conditionValue) && fieldValue === conditionValue;
      };

      const checkSomeCondition = (condition) => {
        const parsedConditions = condition.split('|').filter((c) => !!c);

        const conditionsMet = parsedConditions.some(matchCondition);

        return conditionsMet;
      };

      const checkMultiCondition = (condition) => {
        const multiCondition = condition.split('&').filter((c) => !!c);

        const conditionsMet = multiCondition.every((condition) => {
          if (condition.includes('|')) {
            return checkSomeCondition(condition);
          }

          return matchCondition(condition);
        });

        return conditionsMet;
      };

      const hiddenInputs = Object.keys(operatorConditions).reduce(
        (acc, inputName) => {
          const conditions = operatorConditions?.[inputName] || [];

          const isVisible = conditions.every((condition) => {
            const isMultiCondition = condition.includes('&');

            if (isMultiCondition) {
              const parsedMultiCondition = condition
                .split('|')
                .filter((c) => !!c);

              return parsedMultiCondition.some(checkMultiCondition);
            }

            return checkSomeCondition(condition);
          });

          if (!isVisible) {
            const input = operatorInputs.find(
              (input) => input.name === inputName,
            );

            if (isEmpty(input)) {
              return acc;
            }

            const cleanInput = omit(input, [
              'inputTemplateId',
              'locked',
              'exposed',
              'triggerComputation',
            ]);

            if (normalizeOutput) {
              return {
                ...acc,
                [input.id]: cleanInput,
              };
            }

            return [...acc, cleanInput];
          }

          return acc;
        },
        normalizeOutput ? {} : [],
      );

      return hiddenInputs;
    },
    [
      getOperatorInputs,
      getOperatorFromProvidedArg,
      getInputsVisibilityConditions,
      getProjectFiles,
      getSelectedWorkflowProjectId,
    ],
  );

  const calculateWorkflowHiddenInputs = useCallback(
    ({ formValues = {}, normalizeOutput = false } = {}) => {
      const workflowOperators = getSelectedWorkflowOperators();
      const hiddenInputs = workflowOperators.reduce(
        (acc, operator) => {
          const operatorHiddenInputs = calculateOperatorHiddenInputs(operator, {
            formValues,
            normalizeOutput,
          });

          if (normalizeOutput) {
            return { ...acc, ...operatorHiddenInputs };
          }

          return [...acc, ...operatorHiddenInputs];
        },
        normalizeOutput ? {} : [],
      );

      return hiddenInputs;
    },
    [getSelectedWorkflowOperators, calculateOperatorHiddenInputs],
  );

  const getWorkflowHiddenInputNames = useCallback(
    () => workflowHiddenInputNames || [],
    [workflowHiddenInputNames],
  );

  const getOperatorHiddenInputNames = useCallback(
    (operatorId) => workflowHiddenInputNames?.[operatorId] || [],
    [workflowHiddenInputNames],
  );

  const getOperatorHiddenInputs = useCallback(
    (operatorOrId, { cleanFromApiNotSupportedProps } = {}) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorInputs = getOperatorInputs(operator);
      const hiddenInputNames = getOperatorHiddenInputNames(operator?.id);
      const normalizedDefaultOperators = getNormalizedDefaultOperators();

      const isCombineDataField = (input) => {
        const isListType =
          input?.type === OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES;

        return isListType && input?.name?.startsWith('Data');
      };

      const filteredInputs = operatorInputs?.filter(
        (input) =>
          !isCombineDataField(input) &&
          (!normalizedDefaultOperators?.[operator.name]?.settings?.[
            input.name
          ] ||
            HIDDEN_INPUT_TYPES.includes(input?.type) ||
            hiddenInputNames.includes(input.name)),
      );

      if (cleanFromApiNotSupportedProps) {
        return filteredInputs?.map((input) =>
          omit(input, [
            'inputTemplateId',
            'locked',
            'exposed',
            'triggerComputation',
          ]),
        );
      }

      return filteredInputs;
    },
    [
      getOperatorFromProvidedArg,
      getOperatorInputs,
      getOperatorHiddenInputNames,
      getNormalizedDefaultOperators,
    ],
  );

  const getIsOperatorInputHidden = useCallback(
    (operatorOrId, input) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const hiddenInputs = getOperatorHiddenInputs(operator);

      return hiddenInputs.some((hiddenInput) => hiddenInput.id === input?.id);
    },
    [getOperatorFromProvidedArg, getOperatorHiddenInputs],
  );

  const getOperatorVisibleInputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorInputs = getOperatorInputs(operator);
      const hiddenInputs = getOperatorHiddenInputs(operator);

      return operatorInputs?.filter(
        (input) =>
          !hiddenInputs?.some((hiddenInput) => hiddenInput.id === input.id),
      );
    },
    [getOperatorFromProvidedArg, getOperatorInputs, getOperatorHiddenInputs],
  );

  const getWorkflowHiddenInputs = useCallback(
    ({ cleanFromApiNotSupportedProps } = {}) => {
      const hiddenInputs = getSelectedWorkflowOperators().reduce(
        (acc, operator) => {
          const operatorHiddenInputs = getOperatorHiddenInputs(operator, {
            cleanFromApiNotSupportedProps,
          });

          return [...acc, ...operatorHiddenInputs];
        },
        [],
      );

      return hiddenInputs;
    },
    [getOperatorHiddenInputs, getSelectedWorkflowOperators],
  );

  const getOperatorHiddenOutputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorOutputs = getOperatorOutputs(operator);
      const hiddenInputNames = getOperatorHiddenInputNames(operator.id);
      const normalizedDefaultOperators = getNormalizedDefaultOperators();

      const hiddenOutputs = operatorOutputs?.filter(
        (output) =>
          !normalizedDefaultOperators?.[operator.name]?.settings?.[
            output.name
          ] || hiddenInputNames.includes(output.name),
      );

      return hiddenOutputs || [];
    },
    [
      getOperatorFromProvidedArg,
      getOperatorOutputs,
      getOperatorHiddenInputNames,
      getNormalizedDefaultOperators,
    ],
  );

  const getOperatorVisibleOutputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const operatorOutputs = getOperatorOutputs(operator);
      const hiddenOutputs = getOperatorHiddenOutputs(operator);

      return operatorOutputs?.filter(
        (value) =>
          !hiddenOutputs.some((hiddenOutput) => hiddenOutput.id === value.id),
      );
    },
    [getOperatorFromProvidedArg, getOperatorOutputs, getOperatorHiddenOutputs],
  );

  const getWorkflowHiddenOutputs = useCallback(() => {
    const hiddenInputs = getSelectedWorkflowOperators().reduce(
      (acc, operator) => {
        const operatorHiddenOutputs = getOperatorHiddenOutputs(operator);

        return [...acc, ...operatorHiddenOutputs];
      },
      [],
    );

    return hiddenInputs;
  }, [getOperatorHiddenOutputs, getSelectedWorkflowOperators]);

  const getOperatorInputDescription = useCallback(
    (operatorOrId, inputId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const input = getOperatorInput(operator, inputId);
      const inputName = input?.name;
      const isListType =
        input?.type === OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES;

      // Check if inputName contains "Data" with an optional prefix and an optional numerical suffix, but not just "Data" by itself.
      const dataRegex = /^(.*\.)?Data\d+$/;
      let descriptorInputName = inputName;

      // Test if inputName matches the updated pattern and is a list type.
      if (isListType && dataRegex.test(inputName)) {
        // Extract everything before "Data" (including the dot, if present) and append "Data"
        // This will work for "Data1", "Combine.Data2", "banana1.Data33", etc.
        descriptorInputName = inputName.replace(/(.*\.)?Data\d+$/, '$1Data');
      }

      const descriptor =
        operator?.operatorDescriptor?.operatorValuesDescriptor[
          descriptorInputName
        ];
      const preTranslatedValue = descriptor?.detail;
      const inputDescriptorKey = descriptor?.detailKey;

      const descriptionIsAvailable = !!EnglishTranslation[inputDescriptorKey];
      const description =
        preTranslatedValue ||
        (descriptionIsAvailable
          ? intl.formatMessage({ id: inputDescriptorKey })
          : '');

      return description;
    },
    [intl, getOperatorFromProvidedArg, getOperatorInput],
  );

  const getIsOperatorOutputHidden = useCallback(
    (operatorOrId, output) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const hiddenOutputs = getOperatorHiddenOutputs(operator);

      return hiddenOutputs.some(
        (hiddenOutput) => hiddenOutput.id === output?.id,
      );
    },
    [getOperatorFromProvidedArg, getOperatorHiddenOutputs],
  );

  const getWorkflowFrozenHiddenOutputs = useCallback(
    () =>
      getWorkflowHiddenOutputs().filter((output) =>
        frozenOutputIds?.includes(output.id),
      ),
    [getWorkflowHiddenOutputs, frozenOutputIds],
  );

  const getOperatorVisibleInputsAndOutputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      const visibleInputs = getOperatorVisibleInputs(operator);
      const visibleOutputs = getOperatorVisibleOutputs(operator);

      return concat(visibleInputs, visibleOutputs);
    },
    [
      getOperatorFromProvidedArg,
      getOperatorVisibleInputs,
      getOperatorVisibleOutputs,
    ],
  );

  const getIsOperatorInvalid = useCallback((operator = {}, form = {}) => {
    const formOperatorErrors = form?.formState?.errors?.[operator.id];
    const formOperatorValid = isEmpty(formOperatorErrors);
    const operatorIsInvalid = !formOperatorValid;

    return operatorIsInvalid;
  }, []);

  const getCategorizedVisibleOperatorInputs = useCallback(
    (operatorOrId, { comparisonAware = false } = {}) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      let visibleInputs = getOperatorVisibleInputs(operator);
      const defaultOperatorSettings = getDefaultOperatorSettings(
        operator?.name,
      );

      if (comparisonAware) {
        const comparisonAwareOperatorValues = visibleInputs.filter(
          (fieldInput) => {
            // Check if the field input is modified or removed
            const isModifiedOrRemoved =
              fieldInput?.diffModified || fieldInput?.diffRemoved;

            // If operatorDiff is true, only include modified or removed items
            if (operator.diffOperator) {
              return isModifiedOrRemoved;
            }

            return true;
          },
        );

        visibleInputs = comparisonAwareOperatorValues;
      }

      const categorizedInputs = visibleInputs?.reduce(
        (acc, input) => {
          const defaultOperatorSetting = defaultOperatorSettings?.[input?.name];
          const defaultOperatorSettingCategory =
            defaultOperatorSetting?.settingCategory;
          const noCategory =
            !defaultOperatorSettingCategory ||
            defaultOperatorSettingCategory === 'No Category';

          if (noCategory) {
            acc.default.push(input);

            return acc;
          }

          if (!acc[defaultOperatorSettingCategory]) {
            acc[defaultOperatorSettingCategory] = [];
          }

          acc[defaultOperatorSettingCategory].push(input);

          return acc;
        },
        { default: [] },
      );

      return categorizedInputs;
    },
    [
      getOperatorFromProvidedArg,
      getOperatorVisibleInputs,
      getDefaultOperatorSettings,
    ],
  );

  const getIsOperatorModified = useCallback((operator = {}, form = {}) => {
    const formOperatorValues = form?.getValues(`${operator.id}`) || {};
    const formOperatorInitialValues =
      form?.formState?.defaultValues?.[operator.id] || {};
    const formOperatorVisibleInitialValues = pick(
      formOperatorInitialValues,
      Object.keys(formOperatorValues),
    );

    const formOperatorIsModified = !isEqual(
      formOperatorVisibleInitialValues,
      formOperatorValues,
    );

    return !operator.computed || formOperatorIsModified;
  }, []);

  const getIsOperatorValid = useCallback(
    (operator = {}, form = {}) => {
      operator?.computed &&
        !getIsOperatorInvalid(operator, form) &&
        getIsOperatorModified(operator, form);
    },
    [getIsOperatorInvalid, getIsOperatorModified],
  );

  const getOperatorStatus = useCallback(
    (operator, form) => {
      if (getIsOperatorInvalid(operator, form)) {
        return OPERATOR_HEADER_STATUS_ALERT_MISS;
      }

      if (getIsOperatorModified(operator, form)) {
        return OPERATOR_HEADER_STATUS_ALERT;
      }

      return OPERATOR_HEADER_STATUS_HIGHLIGHT;
    },
    [getIsOperatorInvalid, getIsOperatorModified],
  );

  const getFrozenOperators = useCallback(
    () =>
      getSelectedWorkflowOperators().filter((operator) =>
        operator.values.some((value) => frozenOutputIds?.includes(value.id)),
      ),
    [getSelectedWorkflowOperators, frozenOutputIds],
  );

  const getIsOperatorFrozen = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator.values.some((value) =>
        frozenOutputIds?.includes(value.id),
      );
    },
    [getOperatorFromProvidedArg, frozenOutputIds],
  );

  const getFrozenOperatorInputs = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);

      return operator.values.filter((value) =>
        frozenOutputIds?.includes(value.id),
      );
    },
    [getOperatorFromProvidedArg, frozenOutputIds],
  );

  const getIsOperatorOutputFrozen = useCallback(
    (outputId) => frozenOutputIds?.includes(outputId),
    [frozenOutputIds],
  );

  const getOperatorOutputGroups = useCallback(
    (operator = {}) => {
      const initialOutputGroups = {
        geoOutputs: [],
        nonGeoOutputs: [],
        toolpathOutputs: [],
      };

      if (!operator.computed) {
        return initialOutputGroups;
      }

      const outputs = getOperatorVisibleOutputs(operator);

      if (!outputs) {
        return initialOutputGroups;
      }

      const formattedOutputs = outputs?.reduce((acc, output) => {
        const outputType = output?.type;

        if (GEO_OUTPUT_TYPES.includes(outputType)) {
          acc.geoOutputs.push({ ...output, isGeometricOutput: true });
        } else if (TOOLPATH_OUTPUT_TYPES.includes(outputType)) {
          acc.toolpathOutputs.push({ ...output, isToolpathOutput: true });
        } else if (!VISUALLY_HIDDEN_OUTPUT_TYPES.includes(outputType)) {
          acc.nonGeoOutputs.push(output);
        }

        return acc;
      }, initialOutputGroups);

      return formattedOutputs;
    },
    [getOperatorVisibleOutputs],
  );

  const getOperatorGeometryOutputs = useCallback(
    (operator = {}) => {
      const { geoOutputs = [], toolpathOutputs = [] } =
        getOperatorOutputGroups(operator);
      const geometryOutputs = [...geoOutputs, ...toolpathOutputs];

      return geometryOutputs;
    },
    [getOperatorOutputGroups],
  );

  const getOperatorInfoOutputs = useCallback(
    (operator = {}) => {
      const { nonGeoOutputs = [] } = getOperatorOutputGroups(operator);

      return nonGeoOutputs;
    },
    [getOperatorOutputGroups],
  );

  const getSelectedOperatorByValueId = useCallback(
    (valueId) => {
      const selectedWorkflowOperators = getSelectedWorkflowOperators();

      if (isEmpty(selectedWorkflowOperators)) {
        return;
      }

      const operator = selectedWorkflowOperators.find((operator) =>
        operator?.values?.some((value) => value?.id === valueId),
      );

      return operator;
    },
    [getSelectedWorkflowOperators],
  );

  const getSelectedOperator = useCallback(() => {
    return getSelectedOperatorByValueId(selectedOperatorOutputId);
  }, [selectedOperatorOutputId, getSelectedOperatorByValueId]);

  const getSelectedOperatorOutputGeometryId = useCallback(() => {
    const selectedInput = getOperatorInput(
      selectedOperatorOutput?.operatorId,
      selectedOperatorOutput?.id,
    );

    return selectedInput?.value;
  }, [selectedOperatorOutput, getOperatorInput]);

  const getIsToolpathOperatorSelected = useCallback(() => {
    const selectedOperator = getSelectedOperator();
    const selectedOperatorOutput = getOperatorInput(
      selectedOperator,
      selectedOperatorOutputId,
    );
    const toolpathOperatorNames = [
      TOOLPATH_GENERATOR_OPERATOR_NAME,
      MELTIO_TOOLPATH_GENERATOR_OPERATOR_NAME,
    ];
    const isToolpathOperator =
      selectedOperatorOutput?.type ===
        OperatorInputTypes.TOOLPATH_INSTRUCTIONS ||
      toolpathOperatorNames.some((allowedOperatorName) =>
        selectedOperator?.name?.startsWith(allowedOperatorName),
      );

    return isToolpathOperator;
  }, [getSelectedOperator, selectedOperatorOutputId, getOperatorInput]);

  const getOperatorPreviousDepended = useCallback(
    (operatorOrId) => {
      const operator = getOperatorFromProvidedArg(operatorOrId);
      return getSelectedWorkflowOperators()?.reduce((acc, previousOperator) => {
        const isDependentOperator = previousOperator?.order < operator?.order;

        if (!isDependentOperator) {
          return acc;
        }

        return [...acc, previousOperator];
      }, []);
    },
    [getSelectedWorkflowOperators, getOperatorFromProvidedArg],
  );

  const getOperatorGraphDependencies = useCallback(() => {
    const workflow = getSelectedWorkflow();
    const workflowOperators = getSelectedWorkflowOperators();
    if (!workflowOperators?.length) return;

    return buildGraph(workflow, workflowOperators);
  }, [getSelectedWorkflowOperators, getSelectedWorkflow]);

  const getOperatorFieldOptions = useCallback(
    (operator = {}, fieldInput = {}, { getValues = undefined } = {}) => {
      const { type } = fieldInput;

      if (
        DROP_DOWN_INPUT_FIELD_TYPES.concat(DROP_DOWN_DATA_FIELD_TYPES).includes(
          type,
        )
      ) {
        let inputType = type;
        const previousDependedOperators = getOperatorPreviousDepended(operator);

        if (type === OperatorInputTypes.LIST_GEOMETRY_CLASSIFIED_POLYLINES) {
          inputType = OperatorInputTypes.GEOMETRY_CLASSIFIED_POLYLINES;
        }

        const isCanvasSelectionField = doesFieldSupportCanvasSelection(
          operator,
          fieldInput,
        );

        const dependedOperatorsValues = previousDependedOperators?.reduce(
          (acc, dependedOperator = {}) => {
            const filteredValues = dependedOperator?.values?.filter(
              (value = {}) =>
                !value?.isinput &&
                ((isCanvasSelectionField &&
                  doesOutputSupportCanvasSelection(
                    dependedOperator,
                    getValues,
                  )) ||
                  value?.type === inputType) &&
                value?.operatorId !== operator.id &&
                !getIsOperatorOutputHidden(dependedOperator, value),
            );

            return [...acc, ...filteredValues];
          },
          [],
        );

        const options = dependedOperatorsValues?.map(
          ({ id, operatorId, name }) => {
            const dependedOperator = previousDependedOperators?.find(
              ({ id }) => id === operatorId,
            );

            const title = `${dependedOperator?.tag || ''}.${name}`;

            return {
              label: title,
              tag: dependedOperator?.tag,
              value: id,
              operatorId: dependedOperator?.id,
              isCanvasSelectionOption:
                isCanvasSelectionField &&
                doesOutputSupportCanvasSelection(operatorId, getValues),
              dependencyIsComputed: dependedOperator?.computed,
            };
          },
        );

        return options;
      }

      if (DROP_DOWN_FILE_FIELD_TYPES.includes(type)) {
        const targetFileType = fileTypesByInputType[type];

        const sortedDesigns = getProjectFiles(
          getSelectedWorkflowProjectId(),
        ).sort((a, b) =>
          a.filename.toLowerCase() > b.filename.toLowerCase() ? 1 : -1,
        );

        const filteredDesigns = sortedDesigns.filter(
          (design) =>
            design.visible && targetFileType?.includes(design?.filetype),
        );

        const options = filteredDesigns?.map(({ id, filename: title }) => ({
          label: title,
          value: id,
        }));

        return options;
      }

      const defaultOperator = getDefaultOperators().find(
        ({ name }) => name === operator?.name,
      );

      const defaultOperatorTargetSetting = defaultOperator?.settings.find(
        ({ name }) => name === fieldInput?.name,
      );
      const { allowedValues = [], metaAllowedValues = [] } =
        defaultOperatorTargetSetting || {};

      const options = allowedValues?.map((value) => {
        const metadata = metaAllowedValues?.find(
          ({ value: metaValue }) => metaValue === value,
        );

        return {
          label: value,
          value: value,
          assetIcon: metadata?.assetIcon,
          assetIconUrl: metadata?.assetIconUrl,
          assetImageUrl: metadata?.assetImageUrl,
          description: metadata?.description,
          descriptionKey: metadata?.descriptionKey,
        };
      });

      return options;
    },
    [
      getProjectFiles,
      getSelectedWorkflowProjectId,
      getDefaultOperators,
      getOperatorPreviousDepended,
      getIsOperatorOutputHidden,
      doesFieldSupportCanvasSelection,
      doesOutputSupportCanvasSelection,
    ],
  );

  const getIsComputationAvailable = useCallback(
    (form = {}) => {
      if (activeCanvasSelectionInput) return false;
      if (getIsWorkflowComputing()) return false;

      const {
        formState: { isValid, errors },
      } = form;

      const operators = getSelectedWorkflowOperators();
      const isCalculatingHiddenInputs = !!errors?.root?.blockComputation;

      if (isCalculatingHiddenInputs || !isValid || isEmpty(operators))
        return false;

      const hasInvalidOperators = operators?.some((operator) =>
        getIsOperatorInvalid(operator, form),
      );

      if (hasInvalidOperators) return false;

      const hasModifiedOperators = operators?.some((operator) =>
        getIsOperatorModified(operator, form),
      );

      if (hasModifiedOperators) return true;

      const computedOperators = getComputedOperators();
      const allOperetorsComputed =
        operators?.length === computedOperators?.length;

      return !allOperetorsComputed;
    },
    [
      getIsWorkflowComputing,
      getSelectedWorkflowOperators,
      getComputedOperators,
      getIsOperatorInvalid,
      getIsOperatorModified,
      activeCanvasSelectionInput,
    ],
  );

  const selectOperatorGeometricOutput = useCallback(
    (output = {}) => {
      const operators = getSelectedWorkflowOperators();
      const operatorId = output?.operatorId;
      const operator = operators?.find(
        (operator) => operator.id === operatorId,
      );

      if (!operator) return;

      const showError =
        !output?.value || !operator?.computed || operator?.modified;

      if (showError) {
        dispatch(
          showErrorDialog(
            intl.formatMessage({
              id: 'notvalidcomputationoutputdialog.title',
              defaultMessage: 'Missing data!',
            }),
            intl.formatMessage({
              id: 'notvalidcomputationoutputdialog.description',
              defaultMessage:
                'This output is missing, recompute the operator please',
            }),
          ),
        );

        return;
      }

      dispatch(exitToolpathSimulation());
      dispatch(selectOperatorOutputAction(output));
    },
    [dispatch, intl, getSelectedWorkflowOperators],
  );

  const selectOperatorToolpathOutput = useCallback(
    (output = {}) => {
      const operatorId = output?.operatorId;
      const operator = getOperator(operatorId);

      if (!operator) return;

      if (!operator?.computed) {
        dispatch(
          showErrorDialog(
            intl.formatMessage({
              id: 'notvalidcomputationoutputdialog.title',
              defaultMessage: 'Missing data!',
            }),
            intl.formatMessage({
              id: 'notvalidcomputationoutputdialog.description',
              defaultMessage:
                'This output is missing, recompute the operator please',
            }),
          ),
        );

        return;
      }

      const isSelected = output?.id === selectedOperatorOutputId;
      const isToolpathInstructionType =
        output?.type === OperatorInputTypes.TOOLPATH_INSTRUCTIONS;

      if (isSelected) return;

      dispatch(exitToolpathSimulation());
      dispatch(selectOperatorOutputAction(output));

      if (!isToolpathInstructionType) {
        return;
      }

      dispatch(fetchToolpathOperatorInstructions(operator?.id));
      dispatch(
        fetchToolpathSimulation(
          operatorId,
          (toolpathSimulationUrl, toolpathSimulationPrinterStep) =>
            loadPrintingObject(toolpathSimulationUrl).then((glb) => {
              dispatch(
                enterToolpathSimulation(glb, toolpathSimulationPrinterStep),
              );
            }),
          organizationIdOverride,
        ),
      );
    },
    [
      dispatch,
      intl,
      getOperator,
      selectedOperatorOutputId,
      organizationIdOverride,
    ],
  );

  const selectOperatorOutput = useCallback(
    (output = {}) => {
      const operatorId = output?.operatorId;

      if (!getIsOperatorComputed(operatorId)) {
        return;
      }

      return output?.isGeometricOutput
        ? selectOperatorGeometricOutput(output)
        : selectOperatorToolpathOutput(output);
    },
    [
      getIsOperatorComputed,
      selectOperatorGeometricOutput,
      selectOperatorToolpathOutput,
    ],
  );

  const selectFirstOperatorOutput = useCallback(
    (operator = {}) => {
      const operatorOutputs = getOperatorOutputGroups(operator);
      const { geoOutputs, toolpathOutputs } = operatorOutputs;
      const outputs = [...geoOutputs, ...toolpathOutputs];
      const [firstOutput] = outputs;

      if (!firstOutput) return;

      selectOperatorOutput(firstOutput);
    },
    [getOperatorOutputGroups, selectOperatorOutput],
  );

  const unselectSelectedOperator = useCallback(() => {
    dispatch(exitToolpathSimulation());
    dispatch(selectOperatorOutputAction(null));
  }, [dispatch]);

  const explodeOperator = useCallback(
    (operator) => {
      showDialog(ModalDataTypes.PROMPT, {
        dataTestId: 'explode-operator-dialog',
        title: intl.formatMessage({
          id: 'workflow.operator.modal.explode_title',
          defaultMessage: 'Explode template',
        }),
        subtitle: (
          <>
            <FormattedMessage
              id="workflow.operator.modal.eplode_warning_1"
              defaultMessage="Are you sure that you want to explode the template?"
            />

            <br />

            <FormattedMessage
              id="workflow.operator.modal.eplode_warning_2"
              defaultMessage="It will be exploded into individual operators."
            />

            <br />

            <FormattedMessage
              id="workflow.operator.modal.eplode_warning_3"
              defaultMessage="Your changes will be lost if you don't recompute your workflow first."
            />
          </>
        ),
        onPrimaryButtonClick: () =>
          asyncExplodeOperatorMutation({
            workflowId: operator.conceptId,
            operatorId: operator.id,
          }),
      });
    },
    [intl, showDialog, asyncExplodeOperatorMutation],
  );

  const invalidateDefaultOperatorsQuery = useCallback(
    (workflowId) =>
      queryClient.invalidateQueries({
        queryKey: operatorsQueryKeys.defaultOperators(workflowId),
        refetchType: 'all',
      }),
    [queryClient],
  );

  const getMutationCache = useCallback(
    () => queryClient.getMutationCache(),
    [queryClient],
  );

  const getMutationCacheByKey = useCallback(
    (mutationKey, props = {}) =>
      queryClient.getMutationCache()?.find({ ...props, mutationKey }),
    [queryClient],
  );

  return {
    calculateOperatorHiddenInputs,
    calculateWorkflowHiddenInputs,
    doesFieldSupportCanvasSelection,
    explodeOperator,
    getAllOperatorInputs,
    getCategorizedVisibleOperatorInputs,
    getComputedOperators,
    getDefaultOperator,
    getDefaultOperators,
    getDefaultOperatorSettings,
    getFrozenOperatorInputs,
    getFrozenOperators,
    getInput,
    getInputsVisibilityConditions,
    getIsAddingOperator,
    getIsComputationAvailable,
    getIsDeletingOperator,
    getIsOperatorComputed,
    getIsOperatorComputing,
    getIsOperatorExplodable,
    getIsOperatorFrozen,
    getIsOperatorInputHidden,
    getIsOperatorInvalid,
    getIsOperatorModified,
    getIsOperatorOutputFrozen,
    getIsOperatorOutputHidden,
    getIsOperatorValid,
    getIsReorderingOperator,
    getIsTemplateOperator,
    getIsToolpathOperatorSelected,
    getMutationCache,
    getMutationCacheByKey,
    getNormalizedDefaultOperators,
    getOperator,
    getOperatorFieldOptions,
    getOperatorGeometryOutputs,
    getOperatorGraphDependencies,
    getOperatorHiddenInputNames,
    getOperatorHiddenInputs,
    getOperatorHiddenOutputs,
    getOperatorInfoOutputs,
    getOperatorInput,
    getOperatorInputDescription,
    getOperatorInputs,
    getOperatorOutputGroups,
    getOperatorOutputs,
    getOperatorPreviousDepended,
    getOperatorStatus,
    getOperatorVisibleInputs,
    getOperatorVisibleInputsAndOutputs,
    getOperatorVisibleOutputs,
    getSelectedOperator,
    getSelectedOperatorByValueId,
    getSelectedOperatorOutputGeometryId,
    getTemplates,
    getWorkflowFrozenHiddenOutputs,
    getWorkflowHiddenInputNames,
    getWorkflowHiddenInputs,
    getWorkflowHiddenOutputs,
    getWorkflowRecommendedOperators,
    invalidateDefaultOperatorsQuery,
    selectFirstOperatorOutput,
    selectOperatorGeometricOutput,
    selectOperatorOutput,
    selectOperatorToolpathOutput,
    unselectSelectedOperator,
  };
}
