import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePrevious from '@hooks/usePrevious';
import {
  getIsLoggedIn,
  getIsTokenAuthentificated,
} from '@selectors/loginSelectors';

export default function useDeepLink() {
  const history = useHistory();
  const [deepLink, setDeepLink] = useState(null);
  const tokenAuthentificated = useSelector(getIsTokenAuthentificated);
  const isLoggedIn = useSelector(getIsLoggedIn());
  const previousIsLoggedIn = usePrevious(isLoggedIn);
  const checkForTokenValidity = isLoggedIn && !tokenAuthentificated;
  const currentPath = history.location.pathname;

  useEffect(() => {
    const skip = deepLink || (isLoggedIn && !checkForTokenValidity);

    if (skip) {
      return;
    }

    setDeepLink(currentPath);
  }, [deepLink, isLoggedIn, checkForTokenValidity, currentPath]);

  useEffect(() => {
    const justLoggedIn = !previousIsLoggedIn && isLoggedIn;
    const skip = !deepLink || !justLoggedIn;

    if (skip) {
      return;
    }

    history.push(deepLink);
  }, [deepLink, history, isLoggedIn, previousIsLoggedIn]);
}
